import { Auth } from 'aws-amplify';
import { Client, gql, useClient } from 'urql';
import React, { createContext, useEffect } from 'react';

interface CognitoUser {
  username: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  companyId?: string;
  groups?: string[];
  passwordChallenge: boolean;
}

interface Payload {
  user?: CognitoUser;
}

export interface AuthState {
  user?:CognitoUser;
  isInitialised: boolean;
}

export interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<CognitoUser | undefined>;
  logout: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<CognitoUser | undefined>;
}

const initialAuthState: AuthState = {
  isInitialised: false,
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(undefined),
  logout: () => Promise.resolve(),
  changePassword: () => Promise.resolve(undefined),
});

const reducer = (state: AuthState, action: { type: string, payload?: Payload }): AuthState => {
  switch (action.type) {
    case 'INIT': {
      return {
        ...state,
        isInitialised: true,
        user: action.payload?.user,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        user: action.payload?.user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: undefined,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const GET_COMPANY_QUERY = gql`
  query GetCompany($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapCognitoUser = async (user: any, client: Client): Promise<CognitoUser> => {
  const attributes = user.attributes || {};
  let companyId = attributes['custom:company_id'];
  const groups: string[] = user.signInUserSession?.idToken?.payload['cognito:groups'] || [];

  let companyName;
  if (groups.includes('Users')) {
    const { data } = await client.query<{ company: { id: string; name: string; } }>(
      GET_COMPANY_QUERY,
      { id: companyId },
      { requestPolicy: 'network-only' },
    ).toPromise();

    companyId = data?.company.id;
    companyName = data?.company.name;
  }

  return {
    companyId,
    companyName,
    username: user.username,
    email: attributes.email,
    phone: attributes.phone_number,
    firstName: attributes.given_name,
    lastName: attributes.family_name,
    groups: user.signInUserSession?.idToken?.payload['cognito:groups'] || [],
    passwordChallenge: user.challengeName === 'NEW_PASSWORD_REQUIRED',
  };
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const client = useClient();
  const [state, dispatch] = React.useReducer(reducer, initialAuthState);

  useEffect(() => {
    const init = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        if (!token) {
          dispatch({
            type: 'INIT',
            payload: { user: undefined },
          });
        } else {
          const user = await Auth.currentUserPoolUser();
          dispatch({
            type: 'INIT',
            payload: {
              user: await mapCognitoUser(user, client),
            },
          });
        }
      } catch (error) {
        dispatch({
          type: 'INIT',
          payload: { user: undefined },
        });
      }
    };
    init();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login: async (username, password) => {
          const user = await Auth.signIn(username, password);
          const cognitoUser = await mapCognitoUser(user, client);
          dispatch({
            type: 'LOGIN',
            payload: {
              user: cognitoUser,
            },
          });
          return cognitoUser;
        },
        logout: async () => {
          await Auth.signOut();
          dispatch({ type: 'LOGOUT' });
        },
        changePassword: async (oldPassword, newPassword) => {
          // TODO: error handling
          if (state.user?.passwordChallenge) {
            const user = await Auth.signIn(state.user.username, oldPassword);
            const newUser = await Auth.completeNewPassword(user, newPassword);
            const cognitoUser = await mapCognitoUser(newUser, client);
            dispatch({
              type: 'LOGIN',
              payload: {
                user: cognitoUser,
              },
            });
            return cognitoUser;
          }
          const user = await Auth.currentUserPoolUser();
          await Auth.changePassword(user, oldPassword, newPassword);
          return state.user;
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
