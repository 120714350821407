import React, { FC } from 'react';
import { Container, makeStyles, Theme } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import NavTabs from 'Components/Public/NavTabs';
import LoadingScreen from 'Components/LoadingScreen';
import ComparisonWidget from 'Components/Public/Comparison/ComparisonWidget';
import AlertBox from 'Components/Common/AlertBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: theme.spacing(15.5),
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(14),
      width: '100%',
    },
  },
}));

const PublicLayout: FC<RouteComponentProps> = ({ children }) => {
  const classes = useStyles();
  const { isInitialised } = useAuth();

  if (!isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <NavTabs />
      <Container
        className={classes.content}
        disableGutters
        maxWidth="md"
      >
        <ComparisonWidget />
        {children}
      </Container>
      <AlertBox />
    </div>
  );
};

export default PublicLayout;
