import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'store';
import { gql } from 'urql';
import { User } from 'types/user';
import { useClient } from 'graphql/client';

export interface CompanyAdminInput {
  id?: string;
  name?: string;
}

const initialState: { users: User[] } = { users: [] };

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    editUser(state, action: PayloadAction<{ updatedUser: User }>) {
      const { updatedUser } = action.payload;
      const usersState = state;
      const updatedUserIndex = usersState.users
        .findIndex((user) => user.email === updatedUser.email);
      if (updatedUserIndex > -1) {
        usersState.users[updatedUserIndex] = {
          ...usersState.users[updatedUserIndex],
          ...updatedUser,
        };
      } else {
        throw new Error('User not found');
      }
    },
    removeUser(state, action: PayloadAction<{ email: string }>) {
      const { email } = action.payload;
      const usersState = state;
      const removedUserIndex = usersState.users
        .findIndex((user) => user.email === email);
      if (removedUserIndex > -1) {
        usersState.users.splice(removedUserIndex, 1);
      } else {
        throw new Error('User not found');
      }
    },
    addUser(state, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      const { users } = state;
      const existingUser = users.find((u) => u.email === user.email);
      if (!existingUser) {
        users.push(user);
      } else {
        throw new Error('User already exists');
      }
    },
    setUsers(state, action: PayloadAction<{ newUsers: User[] }>) {
      const { newUsers } = action.payload;
      const usersState = state;
      usersState.users = newUsers;
    },
  },
});

const USERS_QUERY = gql`
  query Search($filter: String) {
    users(filter: $filter) {
      firstName
      lastName
      phone
      email
      companyName
      companyId
      enabled
    }
  }
`;

export const getUsers = ():AppThunk => async (dispatch) => {
  const result = await useClient()
    .query<{ users: User[] }>(
    USERS_QUERY, { filter: '' }, { requestPolicy: 'network-only' },
  )
    .toPromise();
  const { error, data } = result;
  if (error) {
    console.error(result.error);
  } else if (data && data.users) {
    dispatch(usersSlice.actions.setUsers({ newUsers: data.users }));
  }
};

export const selectUser = (
  email: string,
) => (state: RootState): User | undefined => {
  const { users } = state;
  const user = users.users.find((elem) => elem.email === email);
  if (user) {
    return user;
  }
  return undefined;
};

export const selectUsers = (
) => (state: RootState): User[] | undefined => state.users.users;

export const {
  editUser, addUser, setUsers, removeUser,
} = usersSlice.actions;
export default usersSlice.reducer;
