import {
  Company, Network, PowerPlant, NetworkDistributionArea, Fuel,
} from 'types/companyData';

export const findPlant = (
  company: Company,
  networkId: string,
  plantId: string,
): PowerPlant | undefined => {
  const network = company.networks.find((elem) => elem.id === networkId);
  if (network) {
    const plant = network.plants.find((elem) => elem.id === plantId);
    return plant || undefined;
  }
  return undefined;
};

export const findNetwork = (
  company: Company,
  networkId: string,
): Network | undefined => {
  const network = company.networks.find((elem) => elem.id === networkId);
  return network || undefined;
};

export const findArea = (
  company: Company,
  networkId: string,
  localityId: string,
): NetworkDistributionArea | undefined => {
  const network = company.networks.find((elem) => elem.id === networkId);
  if (network) {
    const locality = network.areas.find((elem) => elem.id === localityId);
    return locality || undefined;
  }
  return undefined;
};

export const findPlantFuel = (
  company: Company,
  networkId: string,
  plantId: string,
  fuelId: string,
): Fuel | undefined => {
  const network = company.networks.find((elem) => elem.id === networkId);
  if (network) {
    const plant = network.plants.find((elem) => elem.id === plantId);
    if (plant) {
      const fuel = plant.fuels.find((elem) => elem.id === fuelId);
      return fuel || undefined;
    }
  }
  return undefined;
};
