import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import CompanyTabs from 'Components/Company/CompanyTabs';
import { RouteComponentProps } from 'react-router-dom';
import AlertBox from 'Components/Common/AlertBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    height: '100%',
  },
}));

const CompanyWideLayout: FC<RouteComponentProps> = ({ children, location }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CompanyTabs
        location={location}
        maxWidth="xl"
      >
        {children}
      </CompanyTabs>
      <AlertBox />
    </div>
  );
};

export default CompanyWideLayout;
