import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';

import {
  Client,
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  makeOperation,
} from 'urql';

let client: Client;

const clientFactory = (): Client => {
  client = createClient({
    fetchOptions: {
      headers: {
        'X-Api-Key': process.env.REACT_APP_GRAPHQL_API_KEY || '',
      },
    },
    url: process.env.REACT_APP_GRAPHQL_API || '',
    maskTypename: true,
    exchanges: [
      dedupExchange,
      cacheExchange,
      authExchange<string>({
        getAuth: async ({ authState }) => {
          if (!authState) {
            try {
              const session = await Auth.currentSession();
              return session.getIdToken().getJwtToken();
            } catch (e) {
              return '';
            }
          }

          return '';
        },
        addAuthToOperation: ({
          authState,
          operation,
        }) => {
        // the token isn't in the auth state, return the operation without changes
          if (!authState) {
            return operation;
          }

          // fetchOptions can be a function (See Client API),
          //  but you can simplify this based on usage
          const fetchOptions = typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: authState,
              },
            },
          });
        },
        willAuthError: ({ authState }) => {
          if (!authState) return true;

          try {
            const decoded = jwt_decode<{
              exp: number,
              auth_time: number,
            }>(authState);

            const currentSeconds = Math.floor((new Date()).valueOf() / 1000);
            if (currentSeconds > decoded.exp || currentSeconds < decoded.auth_time) {
              return true;
            }

            return false;
          } catch (error) {
            console.error(error);
            return true;
          }
        },
      }),
      retryExchange({
        initialDelayMs: 500,
        maxDelayMs: 10000,
        randomDelay: true,
        maxNumberAttempts: 3,
        retryIf: (error) => !!(error.graphQLErrors.length > 0 || error.networkError),
      }),
      fetchExchange,
    ],
  });
  return client;
};

export const useClient = (): Client => client;

export default clientFactory;
