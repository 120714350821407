import { createMuiTheme } from '@material-ui/core/styles';

interface ChartTheme {
  fuel: {
    fill: string[];
    getFill: (index: number) => string;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeText {
    link: string;
  }
  interface PaletteOptions {
    chart: ChartTheme
  }
  interface Palette {
    chart: ChartTheme
  }
}

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 900, // default: 600
      md: 1200, // default: 1056
      lg: 1280, // default: 1280
      xl: 1920, // default: 1920
    },
  },
  palette: {
    primary: {
      main: '#0A6FAE',
    },
    secondary: {
      main: '#e5f3fa',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000',
      link: '#666666',
    },
    chart: {
      fuel: {
        fill: [
          '#33B2DF',
          '#546E7A',
          '#D4526E',
          '#13D8AA',
          '#A5978B',
          '#4ECDC4',
          '#C7F464',
          '#81D4FA',
          '#546E7A',
          '#FD6A6A',
        ],
        getFill(i) {
          return this.fill[i % this.fill.length];
        },
      },
    },
  },
  spacing: 9,
  typography: {
    h1: {
      fontFamily: 'Tahoma, Arial, sans-serif',
      fontSize: '3.0rem',
      fontWeight: 500,
      // color: '#0A6FAE', old colour
      color: '#085d91',
      '@media (max-width:600px)': {
        fontSize: '2.125rem',
      },
    },
    h2: {
      fontFamily: 'Tahoma, Arial, sans-serif',
      fontSize: '2.125rem',
      fontWeight: 500,
      color: '#085d91',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontFamily: 'Tahoma, Arial, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 500,
      color: '#085d91',
      '@media (max-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontFamily: 'Tahoma, Arial, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#085d91',
      '@media (max-width:600px)': {
        fontSize: '1.125rem',
      },
    },
    h5: {
      fontFamily: 'Tahoma, Arial, sans-serif',
      fontSize: '1.0rem',
      fontWeight: 500,
      color: '#085d91',
    },
    subtitle1: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '1.125rem',
    },
    subtitle2: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '1.0rem',
    },
    body1: {
      fontSize: '1.125rem',
      fontFamily: 'Arial, sans-serif',
      // color: '#414141', old colour
    },
    body2: {
      fontSize: '1rem',
      fontFamily: 'Arial, sans-serif',
      // color: '#414141', old colour
    },
    button: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '1.0rem',
      // color: '#414141', old colour
    },
  },
});

export default theme;
