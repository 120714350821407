import React from 'react';
import ReactDOM from 'react-dom';

import 'moment/locale/fi';
import 'i18n/config';

import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
