/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  lazy,
  Suspense,
  Fragment,
  FC,
  ReactNode,
} from 'react';

import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';

import PublicLayout from 'Layouts/Public';
import AdminLayout from 'Layouts/Admin';
import CompanyLayout from 'Layouts/Company';
import CompanyWideLayout from 'Layouts/CompanyWide';
import CenteredLayout from 'Layouts/Centered';

import LoadingScreen from 'Components/LoadingScreen';
import CompanyGuard from 'Components/Company/CompanyGuard';
import AuthGuard from 'Components/AuthGuard';
import { useTranslation } from 'react-i18next';
import baseStrings from 'i18n/fi/main';
import svStrings from 'i18n/sv/main';
import enStrings from 'i18n/en/main';

type Routes = {
  exact?: boolean;
  guard?: FC;
  layout?: FC;
  menu?: FC;
  component: FC<RouteComponentProps>;
  path?: string | string[];
  pathFi?: string | string[];
  pathSv?: string | string[];
  pathEn?: string | string[];
}[];

type Layouts = {
  path: string | string[];
  exact: boolean,
  layout?: FC<RouteComponentProps>,
  guard?: FC;
}[];

interface LayoutProps {
  layouts: Layouts;
  children?: ReactNode;
}

/** Defining `Frag` as an alias for `Fragment` avoids the following error:
  * Invalid prop `history` supplied to `React.Fragment`.
  * React.Fragment can only have `key` and `children` props.
  */
const Frag = ({ children }: { children: React.ReactNode }) => (<>{children}</>);

export const Layout:FC<LayoutProps> = ({ layouts, children }) : JSX.Element => (
  <Switch>
    {layouts.map((layout, i) => {
      const Guard = layout.guard || Frag;
      const LayoutType = layout.layout || Frag;

      return (
        <Route
            // eslint-disable-next-line react/no-array-index-key
          key={i}
          path={layout.path}
          exact={layout.exact}
          render={(props) => (
            <Guard>
              <LayoutType {...props}>
                {children}
              </LayoutType>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

const layouts: Layouts = [
  // Routes which have no layout
  {
    path: ['/admin/news/add', '/:lang/admin/news/add', '/admin/news/:id', '/:lang/admin/news/:id'],
    guard: AuthGuard,
    exact: true,
  },
  {
    path: ['/account/changePassword', '/:lang/changePassword', '/login', '/:lang/login', '/forgotPassword', '/:lang/forgotPassword'],
    exact: true,
    layout: CenteredLayout,
  },
  {
    path: ['/admin', '/:lang/admin'],
    exact: false,
    guard: AuthGuard,
    layout: AdminLayout,
  },
  {
    path: ['/company/info', '/:lang/company/info'],
    exact: false,
    guard: AuthGuard,
    layout: CompanyWideLayout,
  },
  {
    path: ['/company', '/:lang/company'],
    exact: false,
    guard: AuthGuard,
    layout: CompanyLayout,
  },
  {
    path: '/',
    exact: false,
    layout: PublicLayout,
  },
];

interface RenderRoutesProps {
  routes: Routes;
}

const languageRedirects = (from: string | string[], to: string | string[]): JSX.Element[] => {
  const target:string = typeof to === 'string' ? to : to[0];
  switch (typeof from) {
    case 'object':
      // eslint-disable-next-line no-case-declarations
      return (
        from.map((path, i) => (
          <Redirect
              // eslint-disable-next-line react/no-array-index-key
            key={i}
            exact
            from={path}
            to={target}
          />
        ))
      );
    default:
      return ([
        <Redirect
          exact
          from={from}
          to={target}
        />,
      ]);
  }
};

export const RenderRoutes:FC<RenderRoutesProps> = ({ routes }): JSX.Element => {
  const { i18n } = useTranslation('main');
  return (
    <>
      <Layout layouts={layouts}>
        <Suspense fallback={<LoadingScreen />}>
          <Switch>
            {routes.map((route, j) => {
              const Component = route.component;
              const LayoutRoute = route.layout || Fragment;
              const Guard = route.guard || Fragment;
              if (route.pathFi !== undefined
                && route.pathSv !== undefined
                && route.pathEn !== undefined) {
                const routesJSX = [
                  <Route
                    path={route.pathFi}
                    exact={route.exact}
                    render={(props) => (
                      <Guard>
                        <LayoutRoute>
                          <Component {...props} />
                        </LayoutRoute>
                      </Guard>
                    )}
                  />,
                  <Route
                    path={route.pathSv}
                    exact={route.exact}
                    render={(props) => (
                      <Guard>
                        <LayoutRoute>
                          <Component {...props} />
                        </LayoutRoute>
                      </Guard>
                    )}
                  />,
                  <Route
                    path={route.pathEn}
                    exact={route.exact}
                    render={(props) => (
                      <Guard>
                        <LayoutRoute>
                          <Component {...props} />
                        </LayoutRoute>
                      </Guard>
                    )}
                  />,
                ];

                return (
                  [
                    ...(i18n.language === 'en') ? languageRedirects(route.pathFi!, route.pathEn!) : [],
                    ...(i18n.language === 'en') ? languageRedirects(route.pathSv!, route.pathEn!) : [],

                    ...(i18n.language === 'sv') ? languageRedirects(route.pathFi!, route.pathSv!) : [],
                    ...(i18n.language === 'sv') ? languageRedirects(route.pathEn!, route.pathSv!) : [],

                    ...(i18n.language === 'fi') ? languageRedirects(route.pathEn!, route.pathFi!) : [],
                    ...(i18n.language === 'fi') ? languageRedirects(route.pathSv!, route.pathFi!) : [],
                    ...routesJSX,
                  ]);
              }
              return (
                <Route
                // eslint-disable-next-line react/no-array-index-key
                  key={j}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                    <Guard>
                      <LayoutRoute>
                        <Component {...props} />
                      </LayoutRoute>
                    </Guard>
                  )}
                />
              );
            })}
          </Switch>
        </Suspense>
      </Layout>
    </>
  );
};
const routes: Routes = [

  // Public

  {
    path: '/laskepaastosi/:name?/:id?/:companyId?',
    pathFi: `${baseStrings.routes.calculateYourEmissions}/:name?/:id?/:companyId?`,
    pathEn: `${enStrings.routes.calculateYourEmissions}/:name?/:id?/:companyId?`,
    pathSv: `${svStrings.routes.calculateYourEmissions}/:name?/:id?/:companyId?`,
    exact: true,
    component: lazy(() => import('./Views/Public/Wizard')),
  },
  {
    path: '/mikalaskuri',
    pathFi: baseStrings.routes.whatCalculator,
    pathEn: enStrings.routes.whatCalculator,
    pathSv: svStrings.routes.whatCalculator,
    exact: true,
    component: lazy(() => import('./Views/Public/WhatCalculator')),
  },
  {
    path: '/tausta',
    pathFi: baseStrings.routes.background,
    pathEn: enStrings.routes.background,
    pathSv: svStrings.routes.background,
    exact: true,
    component: lazy(() => import('./Views/Public/Background')),
  },
  {
    path: '/kehitys',
    pathFi: baseStrings.routes.development,
    pathEn: enStrings.routes.development,
    pathSv: svStrings.routes.development,
    exact: true,
    component: lazy(() => import('./Views/Public/Development')),
  },
  {
    path: '/laskentatavat',
    pathFi: baseStrings.routes.methods,
    pathEn: enStrings.routes.methods,
    pathSv: svStrings.routes.methods,
    exact: true,
    component: lazy(() => import('./Views/Public/Methods')),
  },
  {
    path: ['/ajankohtaista/:article'],
    exact: true,
    component: lazy(() => import('./Views/Public/News/Article')),
  },
  {
    path: ['/ajankohtaista'],
    pathFi: baseStrings.routes.news,
    pathEn: enStrings.routes.news,
    pathSv: svStrings.routes.news,
    exact: true,
    component: lazy(() => import('./Views/Public/News/Rss')),
  },
  {
    path: '/paastot/:name/:id?/:companyId?/',
    pathFi: `${baseStrings.routes.emissions}/:name?/:id?/:companyId?`,
    pathSv: `${svStrings.routes.emissions}/:name?/:id?/:companyId?`,
    pathEn: `${enStrings.routes.emissions}/:name?/:id?/:companyId?`,
    exact: true,
    component: lazy(() => import('./Views/Public/ResultView')),
  },
  {
    path: '/vertaile',
    pathFi: baseStrings.routes.compare,
    pathEn: enStrings.routes.compare,
    pathSv: svStrings.routes.compare,
    exact: true,
    component: lazy(() => import('./Views/Public/ComparisonView')),
  },
  {
    path: '/',
    pathFi: baseStrings.routes.home,
    pathEn: enStrings.routes.home,
    pathSv: svStrings.routes.home,
    exact: true,
    component: lazy(() => import('./Views/Public')),
  },

  // {
  // NOTE: don't allow further at this moment
  // TODO: set only for production
  //   component: () => <Redirect to="/" />,
  // },

  // Company

  {
    exact: true,
    path: '/company/general',
    component: lazy(() => import('./Views/Company/General')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company',
    component: lazy(() => import('Views/Company')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/add/emissions',
    component: lazy(() => import('Views/Company/Add/Emissions')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/add/trades',
    component: lazy(() => import('Views/Company/Add/Trades')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company',
    component: lazy(() => import('./Views/Company')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/add/emissions',
    component: lazy(() => import('./Views/Company/Add/Emissions')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/general',
    component: lazy(() => import('./Views/Company/General')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/info',
    component: lazy(() => import('./Views/Company/Info/Emissions')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/tradesInfo',
    component: lazy(() => import('./Views/Company/Info/Trades')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/preview',
    component: lazy(() => import('./Views/Company/Preview')),
    guard: CompanyGuard,
  },
  {
    exact: true,
    path: '/company/account/',
    component: lazy(() => import('./Views/Company/Account')),
    guard: CompanyGuard,
  },

  // ADMIN

  {
    path: '/admin/users',
    exact: true,
    component: lazy(() => import('./Views/Admin/Users')),
  },
  {
    path: '/admin/companies',
    exact: true,
    component: lazy(() => import('./Views/Admin/Companies')),
  },
  {
    path: '/admin',
    exact: true,
    component: lazy(() => import('./Views/Admin')),
  },
  {
    path: '/admin/news',
    exact: true,
    component: lazy(() => import('./Views/Admin/News')),
  },
  {
    path: '/admin/news/add',
    exact: true,
    component: lazy(() => import('./Views/Admin/News/Add')),
  },
  {
    path: '/admin/news/:article',
    exact: true,
    component: lazy(() => import('./Views/Admin/News/Add')),
  },
  {
    path: '/admin/logs',
    exact: true,
    component: lazy(() => import('./Views/Admin/Logs')),
  },

  // Common

  {
    path: '/login',
    exact: true,
    component: lazy(() => import('./Views/Common/Login')),
  },
  {
    path: '/forgotPassword',
    exact: true,
    component: lazy(() => import('./Views/Common/ForgotPassword')),
  },
  {
    path: '/account/changePassword',
    exact: true,
    component: lazy(() => import('./Views/Common/ChangePassword')),
  },
  {
    component: () => <Redirect to="/" />,
  },
];

export default routes;
