import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TargetType } from 'types/calculation';

// wip, not in use

interface SelectedTargetState {
  target?: SelectedTarget;
}

interface SelectedTarget {
  id: string;
  companyId?: string;
  name: string;
  type: TargetType;
}

const initialState:SelectedTargetState = {
  target: undefined,
};

const selectedTargetSlice = createSlice({
  name: 'selectedTarget',
  initialState,
  reducers: {
    clearSelection(state) {
      const stateVar = state;
      stateVar.target = undefined;
    },
    setTarget(state, action: PayloadAction<
    { id: string, companyId?: string, name: string, type: TargetType }
    >) {
      const {
        id, companyId, name, type,
      } = action.payload;
      const stateVar = state;
      stateVar.target = {
        id, companyId, name, type,
      };
    },
  },
});

export const {
  clearSelection,
  setTarget,
} = selectedTargetSlice.actions;

export default selectedTargetSlice.reducer;
