/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
  },
}));

const LanguageSelectLinks:FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('main');
  return (
    <Typography
      className={classes.text}
      variant="subtitle2"
    >
      <Link
        component={NavLink}
        className={classes.text}
        to="/"
        onClick={(e:any) => {
          e.preventDefault();
          i18n.changeLanguage('fi');
        }}
        underline={i18n.language === 'fi' ? 'always' : 'hover'}
      >
        Fi
      </Link>
      {' / '}
      <Link
        component={NavLink}
        className={classes.text}
        to="/"
        onClick={(e:any) => {
          e.preventDefault();
          i18n.changeLanguage('sv');
        }}
        underline={i18n.language === 'sv' ? 'always' : 'hover'}
      >
        Sv
      </Link>
      {' / '}
      <Link
        component={NavLink}
        className={classes.text}
        to="/"
        onClick={(e:any) => {
          e.preventDefault();
          i18n.changeLanguage('en');
        }}
        underline={i18n.language === 'en' ? 'always' : 'hover'}
      >
        En
      </Link>
    </Typography>
  );
};

export default LanguageSelectLinks;
