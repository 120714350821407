import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { CalculationMethod } from 'types/calculation';

export type MethodType = CalculationMethod.EFFICIENCY | CalculationMethod.ENERGY;

interface CalculationMethodState {
  method: MethodType;
}

const initialState:CalculationMethodState = {
  method: CalculationMethod.EFFICIENCY,
};

const calculationMethodSlice = createSlice({
  name: 'calculationMethod',
  initialState,
  reducers: {
    setMethod(state, action:PayloadAction<{ method: MethodType }>) {
      const { method } = action.payload;
      const stateVar = state;
      if (stateVar.method !== method) stateVar.method = method;
    },
  },
});

export const calculationMethodSelector = (
  state: RootState,
):MethodType => state.calculationMethod.method;

export const {
  setMethod,
} = calculationMethodSlice.actions;

export default calculationMethodSlice.reducer;
