import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Severity = 'error' | 'warning' | 'info' | 'success';

interface AlertSliceState {
  enabled: boolean;
  message: string;
  severity: Severity;
  timeMs: number;
}

const initialState:AlertSliceState = {
  enabled: false,
  message: '',
  severity: 'error',
  timeMs: 15000,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert(state, action: PayloadAction<
    { message: string, severity: Severity, timeMs?: number }
    >) {
      const {
        message, severity, timeMs,
      } = action.payload;
      const newState = state;
      newState.enabled = true;
      newState.message = message;
      newState.severity = severity;
      newState.timeMs = timeMs ?? initialState.timeMs;
    },
    hideAlert(state) {
      const newState = state;
      newState.enabled = false;
    },
  },
});

export const {
  setAlert,
  hideAlert,
} = alertSlice.actions;

export default alertSlice.reducer;
