import { Provider as ReduxProvider } from 'react-redux';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import theme from 'theme';
import store from 'store';
import ErrorView from 'Views/Common/Error';
import UrqlProvider from 'graphql/Provider';
import routes, { RenderRoutes } from 'routes';
import GlobalStyles from 'Components/GlobalStyles';
import { ErrorBoundary } from 'react-error-boundary';

import 'Amplify/configure';
import { AuthProvider } from 'contexts/AuthContext';
import LanguageSetter from 'Components/Common/LanguageSetter';

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <UrqlProvider>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <GlobalStyles />
          <AuthProvider>
            <ErrorBoundary FallbackComponent={ErrorView}>
              <LanguageSetter>
                <RenderRoutes routes={routes} />
              </LanguageSetter>
            </ErrorBoundary>
          </AuthProvider>
        </BrowserRouter>
      </ReduxProvider>
    </UrqlProvider>
  </ThemeProvider>
);

export default App;
