const baseStrings = {
  routes: {
    home: '/',
    whatCalculator: '/mikalaskuri',
    background: '/tausta',
    login: '/kirjautuminen',
    development: '/kehitys',
    news: '/ajankohtaista',
    methods: '/laskentatavat',
    emissions: '/paastot',
    wizard: '/laske',
    compare: '/vertaile',
    calculateYourEmissions: '/laskepaastosi',
  },
  background: `Kaukolämmön päästölaskuri on Paikallisvoima ry:n ja suomalaisten kaukolämpöyhtiöiden \
  yhteishanke, jonka tarkoituksena on tarjota kaukolämpöasiakkaille sekä kaukolämmöstä kiinnostuneille \
  toimijoille läpinäkyvä ja vertailukelpoinen kaukolämmön päästölaskuri.\n\n\
  \
  Kuluttajille ja yrityksille suunnattuja päästölaskureita on olemassa paljon, \
  mutta kaukolämmön päästöihin keskittyvää palvelua ei ollut olemassa.\n\n\
  \
  Aikaisemmin kaukolämmön keskimääräisiin päästöihin perustuvat laskurit ovat antaneet \
  kaukolämmölle samat päästöt polttoaineista riippumatta. Keskimääräiset päästöt ovat olleet \
  yksinkertaistettu tapa kuvata tilannetta, koska paikkakuntakohtaisesti kaukolämmön tuotantotavat \
  vaihtelevat merkittävästi . Tähän asti päästöjen laskennassa käytetyt tilastotovat päivittyneet \
  toisinaan erittäin myöhään. Lisäksi tilastoihin tulevat tiedot ovat olleet hajallaan ja myös \
  eri mitallisia (esim. päästökaupan alaiset tuotantolaitokset ja ei-päästökaupan alaiset).\n\n\
  \
  Kaukolämmön päästölaskuri korjaa nämä puutteet. Se on ohjelmoitu hakemaan asiakkaan asuinpaikan \
  perusteella kyseisen paikkakunnan kaukolämpöverkkoon tuotetun lämmön hiilidioksidipäästöt. \
  Päästölaskuri kattaa noin 99% koko maan kaukolämpötiedoista ja tarkempia, kuukausitasoisia \
  tietoja siihen syöttää tällä hetkellä yli 75% kaikista Suomessa toimivista kaukolämpöyhtiöistä \
  ja määrä kasvaa jatkuvasti. Avoimuus tietojen keräämisessä ja läpinäkyvyys paikkakuntien \
  kaukolämpöverkkojen  osalta ovat  laskurissa keskeisiä.\n\n\
  \
  Paikallisvoima ry aloitti laskurin \
  kehittämisen keväällä 2020. Sen taustaselvitys löytyy <2>tästä linkistä</2>.\n\n\
  \
  Kaukolämmön päästölaskurissa käytetyt parametrit ovat yhteismitallisia ja noudattavat Tilastokeskuksen \
  virallisia menetelmiä ja parametreja. Laskuri huomioi myös päästötiedot, jotka perustuvat tarkempaan \
  lainsäädäntöön, kuten päästökauppaan. Päästökaupan alaisissa laitoksissa mitataan ja todennetaan \
  useita asioita Tilastokeskusta tarkemmin. Nämä todennukset noudattavat tarkempaa lainsäädäntöä \
  ja näin tarkentavat tuotannon hiilidioksidipäästöjen laskentaa. Laskuri tulee kertomaan, kuuluuko \
  laitos päästökauppaan vai ei.\n\nPerinteisesti tilastoinnissa on kuvattu päästöjä kalenterivuosittain \
  taaksepäin. Kaukolämmön päästölaskuriin mukana olevat yhtiöt voivat syöttää tuotantotiedot kuukausittain, \
  jolloin loppukäyttäjät saavat ajankohtaisempaa tietoa toteutuneesta kaukolämmön tuotannosta.\n\n\
  \
  Päästölaskurin kehitystyön ohjausryhmässä ovat olleet mukana Suomen Ympäristökeskus, Kuntaliitto, \
  Tilastokeskus, Energiavirasto, Motiva, Energiateollisuus ry, sekä Palveleva Kaukolämpö FinDHC ry. \
  Päästölaskurin teknisestä toteutuksesta ja ylläpidosta vastaa Akamon Innovations Oy. Kaukolämmön \
  päästölaskuri avattiin julkiseen käyttöön 11.2.2022. Kaikki hiidioksidipäästölaskureita kehittävät ${''}\
  tahot voivat hyödyntää sen tietoja avoimien rajapintojen kautta omissa laskureissaan.`,

  development: `Kaukolämmön päästölaskurin kehityksestä vastaa kaukolämpöyhtiöiden tekninen asiantuntijaryhmä. \
  Kehityksessä huomioidaan kaukolämmön käyttäjiltä ja päästötietoja tarvitsevilta esiin nousevat kehityskohteet, \
  lainsäädännöstä tulevat muutostarpeet sekä kaukolämmön tuottajien tarpeet. Tekninen asiantuntijaryhmä koostuu \
  asiantuntijoista eri toiminta-alueittain.\n\n\
  \
  Mukana on niin ympäristöraportoinnin, asiakaspalvelun, myynnin, tekniikan ja tuotannon kuin asennuksen ja \
  tietojärjestelmien asiantuntijoita. Asiantuntijaryhmään voit lähettää kehitysehdotuksia \
  <2>tämän sähköpostilinkin kautta</2>. Kehitystä koordinoi Paikallisvoima ry. \
  Projektipäällikkönä toimii Jari Nykänen. Voit olla yhteydessä kaukolämmön päästölaskurin kehityksessä ${''}\
  sekä kysymyksissä myös Paikallisvoimaan: jari.nykanen@paikallisvoima.fi.`,

  whatCalculator: `Nimensä mukaisesti kaukolämmön päästölaskuri laskee ja esittää tarkasti sekä \
  yhdenmukaisesti kaukolämmön paikkakunta- ja kaukolämpöverkkokohtaiset hiilidioksidipäästöt. \
  Palvelun käyttö on käyttäjille maksutonta.\n\nLähtötiedot eli kaukolämpöverkostojen tiedot \
  syötetään verkkokohtaisesti järjestelmään. Jokainen verkko saa kuntatiedon, minkä perusteella \
  käyttäjä saa tietonsa omalta asuinpaikkakunnalta. On olemassa kaukolämpöverkkoja, jotka \
  sijaitsevat useamman kunnan alueella, sekä kuntia, joissa on useampi kaukolämpöverkko. \
  Käyttäjän on hyvä tietää, minkä energiayhtiön ja kaukolämpöverkon asiakas hän on.\n\n\
  \
  Energiayhtiöt syöttävät laskuriin käytetyt tuotantojakaumat eli polttoaineet ja muut tuotantomenetelmät \
  (esim. lämpöpumpuilla tuotettu lämpö). Tiedot syötetään Tilastokeskuksen virallisten ja standardoitujen \
  määritelmien mukaisesti. Käytetyt polttoaineet saavat Tilastokeskuksen mukaiset polttoaineiden \
  päästökertoimet. Mikäli energiayhtiön tuotantolaitos on mukana EU:n päästökaupassa, käytetään \
  päästöluvan tarkkailusuunnitelmassa kuvattuja mittausmenetelmiä, kuten analysoituja polttoaineiden \
  päästökertoimia. Vain näitä tarkkoja ja mitattuja lähtökohtatietoja voi käyttää Tilastokeskuksen \
  kertoimien tilalla. Energiayhtiöt voivat syöttää laskuriin tietoja kuukausikohtaisesti, aina edellisen \
  toteutuneen kuukauden tietojen mukaan. Tilastoidut tiedot ovat noin vuoden vanhoja, johtuen \
  tilastoinnin tarkastus- ja julkaisuvaatimuksista.\n\nLaskurissa on saatavilla viiden edellisen vuoden \
  päästötiedot, sekä yhtiöiden kaukolämmön tuotantojakaumat. Käyttäjä voi tietojen avulla laskea oman \
  kaukolämmön käyttönsä päästöt viimeisimmän käytössä olevan täyden vuoden päästökertoimen avulla, joko ${''}\
  syöttämällä kulutustietonsa tai arvioimalla ne talotyypin, pinta-alan ja rakennusvuoden perusteella.`,
  methods: {
    content: `Päästölaskurin tietosisältö perustuu yhtiöiden toimittamiin virallisiin tietoihin. \
    Vuosien 2016–2020 päästötiedot on laskettu Energiateollisuus ry:n vuosittaisiin tilastojulkaisuihin \
    keräämien tietojen perusteella. Näissä kaukolämpötilastoissa tiedot on julkaistu kuntakohtaisesti \
    kaukolämmön myyjittäin. Tilastojulkaisut löytyvät Energiateollisuuden sivuilta:\n\
    <2>https://energia.fi/uutishuone/materiaalipankki/kaukolampotilasto.html#material-view</2>\n\n\
    \
    Käytettävien polttoaineiden ominaispäästökertoimet on laskettu Tilastokeskuksen vuosittain \
    julkaisemien polttoaineluokitusten perusteella. Polttoaineluokitukset löytyvät tilastokeskuksen \
    sivuilta:\n\
    <6>https://www.stat.fi/tup/khkinv/khkaasut_polttoaineluokitus.html</6>\n\n\
    \
    Koska Energiateollisuuden tilastojulkaisuissa tiedot ovat kuntatasolla, myös niiden perusteella \
    tehty päästökertoimien ja päästöjen laskenta tehdään kaukolämmön myyjittäin kuntakohtaisesti ja \
    tuotantomenetelmittäin. Laskennassa huomioidaan kaukolämmön myyjän oman kaukolämmöntuotannon lisäksi \
    myyjien välinen kaukolämpökauppa. Myydyn kaukolämpöerän tuottamisesta syntyneet \
    CO<9>2</9>-päästöt lasketaan osaksi ostajan päästöjä myyjän kaukolämmön tuotannon laskennallisten \
    päästöjen keskiarvon perusteella. Vuoden 2021 alusta alkaen kaukolämmön myyjillä on mahdollisuus \
    syöttää myymänsä kaukolämmön tuotantotiedot laskuriin suoraan tuotantolaitoskohtaisesti. Laskuri \
    mahdollistaa tietojen syötön myyjän valitsemilla aikajaksoilla, esimerkiksi kuukausittain. \
    Laskuriin syötettävät myyjien väliset kaukolämpökaupat sisältävät tiedot myytävän kaukolämpöerän \
    tuotantotiedoista. Laskuri laskee laskuriin syötettyjen tietojen perusteella päästökertoimet \
    ja päästöt laitoskohtaisesti ja kaukolämpökauppojen syötettyjen täsmällisten tietojen pohjalta.\n\n\
    \
    Vaarallinen jäte (entiseltä nimeltään ongelmajäte) lasketaan nollapäästöisenä.\n\n\
    ${''}\
    Laitoskohtaisesti ja kuntakohtaisesti lasketut tulokset voivat poiketa hieman toisistaan.`,
    usedCalculationMethods: 'Päästölaskurissa käytetyt laskukaavat',
  },
  appbar: {
    home: 'Etusivu',
    whatCalculator: 'Mikä laskuri?',
    background: 'Tausta',
    companies: 'Mukana olevat yhtiöt',
    development: 'Kehitys',
    methods: 'Laskentatavat',
    news: 'Ajankohtaista',
    company: 'Yhtiölle',
    admin: 'Admin',
    login: 'Kirjautuminen',
  },
  pageTitles: {
    home: 'Paikallisvoima | Kaukolämmön päästölaskuri',
    whatCalculator: 'Mikä laskuri? - Kaukolämmön päästölaskuri | Paikallisvoima',
    background: 'Tausta - Kaukolämmön päästölaskuri | Paikallisvoima',
    development: 'Kehitys - Kaukolämmön päästölaskuri | Paikallisvoima',
    methods: 'Laskentatavat - Kaukolämmön päästölaskuri | Paikallisvoima',
    results: '{{target}} - Kaukolämmön CO2-päästöt - Kaukolämmön päästölaskuri | Paikallisvoima',
    wizard: 'Laske omat päästösi - Kaukolämmön päästölaskuri | Paikallisvoima',
    news: 'Ajankohtaista - Kaukolämmön päästölaskuri | Paikallisvoima',
  },
  accounts: {
    changePasswordButton: 'Vaihda salasana',
    changePasswordTitle: 'Vaihda käyttäjätunnuksen salasana',
    changePasswordHelper: 'Salasanan pitää olla vähintään 10 merkkiä pitkä, ja siinä pitää olla vähintään yksi iso kirjain, pieni kirjain, numero ja erikoismerkki.',
    oldPassword: 'Vanha salasana',
    newPassword: 'Uusi salasana',
    newPasswordAgain: 'Uusi salasana uudelleen',
    errorTextLength: 'Salasanan pitää olla vähintään 10 merkkiä pitkä',
    errorTextCharacters: 'Salasanassa pitää olla vähintään yksi iso kirjain, pieni kirjain, numbero ja erikoismerkki',
    errorTextNotSame: 'Uusien salasanojen pitää olla samoja',
    errorTextNotEmpty: 'Kenttä ei voi olla tyhjä',
    errorTextMustDifferFromOld: 'Uuden salasanan pitää olla eri kuin vanha salasana',
    forgotPassword: 'Unohdin salasanani',
    forgotPasswordTitle: 'Lähetämme sähköpostiisi linkin salasanan vaihtoon',
    userDoesNotExist: 'Syöttämälläsi sähköpostiosoitteella ei ole käyttäjätunnusta',
    wrongUsernameAndOrPassword: 'Väärä sähköpostiosoite ja/tai salasana',
    code: 'Syötä koodi',
    username: 'Käyttäjätunnus',
    password: 'Salasana',
    passwordChangedSuccesfully: 'Salasanan vaihtaminen onnistui!',
    returnToLoginPage: 'Palaa takaisin kirjautumissivulle',
    userNotFoundException: 'Salasanan vaihtaminen epäonnistui, käyttäjätunnusta ei ole olemassa',
    codeMismatchException: 'Salasanan vaihtaminen epäonnistui, virheellinen salasanan vaihtokoodi',
    codeDeliveryPhone: 'Koodi salasanan vaihtoon lähetettiin numeroon {{phone}}.',
    codeDeliveryEmail: 'Koodi salasanan vaihtoon lähetettiin sähköpostiisi {{email}}.',
    login: 'Kirjaudu sisään',
    logout: 'Kirjaudu ulos',
    email: 'Sähköpostiosoite',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    phone: 'Puhelinnumero',
    companyName: 'Yhtiön nimi',
    title: 'Päästölaskuri',
    sendLinkButton: 'Lähetä',
  },
  header: 'Tervetuloa käyttämään kaukolämmön päästölaskuria!',

  subHeader: `Kaukolämmön päästölaskurista näet haluamasi kaukolämpöyhtiön tai kunnan \
  hiilidioksidipäästöt, voit vertailla eri kuntien ja/tai kaukolämpöyhtiöiden ${''}\
  hiilidioksidipäästöjä ja laskea omat hiilidioksidipäästösi.`,

  subHeaderMore: `Laskuri kertoo verkostokohtaisen kaukolämmön tuotannon päästökertoimen \
  ja päästöt, mitkä voivat poiketa asiakaskohtaisesta erikseen kaukolämmön myyjän kanssa \
  sopimasta kaukolämmön tuotannosta. Asiakkailla on mahdollista ostaa monelta ${''}\
  kaukolämpötoimijalta päästöttömillä tuotantomenetelmillä tuotettua kaukolämpöä.`,

  searchBar: {
    label: 'Hae kaukolämpöyhtiö tai kunta',
    placeholder: 'Voit hakea kaukolämpöyhtiön tai kunnan nimellä',
    clear: 'Tyhjennä',
    loadingText: 'Ladataan...',
    noResults: 'Ei tuloksia',
    groups: {
      city: 'Kunnat',
      company: 'Kaukolämpöverkot',
    },
  },
  searchBarNetwork: {
    label: 'Hae kaukolämpöyhtiötä',
    placeholder: 'Voit hakea kaukolämpöyhtiön nimellä',
  },
  searchBarInfo: 'Tarkista kaukolämpöyhtiön tai kunnan päästöt.',
  buttonInfo: 'Mikäli olet kaukolämmön käyttäjä, voit myös laskea omat päästösi.',
  calculateEmissionButton: 'Laske omat päästösi',
  returnHomeButton: 'Etusivulle',
  wizard: {
    locationStep: 'Kaukolämpöyhtiö',
    processStep: 'Lähtötiedot',
    inputStep: 'Tietojen syöttö',
    homeStep: 'Talotyyppi',
    constructionYearStep: 'Rakennusvuosi',
    surfaceAceaStep: 'Pinta-ala',
    consumptionStep: 'Lämmönkulutus',
    consumption: 'Lämmönkulutus vuodessa',
    constructionYear: 'Talon rakennusvuosi',
    surfaceArea: 'Kodin pinta-ala',
    nextButton: 'Seuraava',
    backButton: 'Takaisin',
    calculateButton: 'Laske päästösi',
    personalResultsTitle: 'Omat kaukolämmön päästösi',
    processType: {
      consumption: 'Tiedän lämmönkulutukseni',
      selection: 'En tiedä lämmönkulutustani',
    },
    homeType: {
      HOUSE: 'Omakotitalo',
      TERRACED_HOUSE: 'Rivitalo',
      FLAT: 'Kerrostalo',
    },
  },
  result: {
    headerNetwork: 'Kaukolämpöverkon päästöt',
    headerArea: 'Kunnan kaukolämmön päästöt',
    noResultNetwork: 'Laskurissa ei ole tällä hetkellä päästöjä verkolle {{name}}',
    noResultArea: 'Laskurissa ei ole tällä hetkellä päästöjä kunnalle {{name}}',
    subHeader: 'Vuoden {{year}} laskennalliset päästöt',
    emissionFactor: 'Päästökerroin',
    emission: 'Hiilidioksidipäästöt',
    showInfo: 'Näytä päästöjen kehitys',
    hideInfo: 'Piilota päästöjen kehitys',
    includeNetworkLosses: 'Laskennassa huomioidaan verkkohäviöt',
    addToComparison: 'Lisää vertailuun',
    calculationMethod: 'Päästöjen laskentamenetelmä',
    calculationMethodInfoTitle: 'Lisätietoa laskentamenetelmistä',
    calculationMethodInfoBody: 'Lämmöntuotannon päästöjä ja päästökertoimia voidaan tarkastella useammalla eri menetelmällä. Tässä laskurissa on kaksi yleisimmin käytössä olevaa tapaa:\n\nHyödynjakomenetelmässä lämmön ja sähkön yhteistuotannolla saavutetun korkean hyötysuhteen katsotaan hyödyttävän kumpaakin, sekä sähkön- että lämmöntuotantoa. Hyödynjakomenetelmässä huomioidaan erillisillä sähkön- ja lämmöntuotannoilla tuotetun energian hyötysuhteet sekä tuotetun energian määrä. Polttoaineet ja päästöt jaetaan suhteessa tuotantojen polttoainekulutuksille.\n\nEnergiamenetelmä on laskentamenetelmistä yksinkertaisempi. Energiamenetelmässä polttoaineet ja päästöt jaetaan tuotettujen energioiden suhteessa. Esimerkiksi jos tuotetusta kokonaisenergiasta 70 % on lämpöä ja 30 % sähköä, katsotaan, että lämmöntuotantoon on kulunut 70 % polttoaineista ja sähköntuotantoon 30 %.\n\nLisätietoa käytetyistä menetelmistä löytyy <2>tästä linkistä</2>.',
    fuels: 'Tuotantojakauma',
    realized: 'Toteutuneet',
    planned: 'Suunnitelma',
    predictedYear: 'Ennuste loppuvuodelle',
    predictionLegend: 'Ennuste',
    calculationMethods: {
      ENERGY: 'Energiamenetelmä',
      EFFICIENCY: 'Hyödynjakomenetelmä',
    },
    productionType: 'Näytä tiedot kohteelle',
    productionTypes: {
      DISTRICT_HEATING: 'Kaukolämpö',
      ELECTRICITY: 'Sähkö',
      PROCESS_HEAT: 'Prosessihöyry',
      FUELS: 'Polttoaineet',
      PLANTS: 'Laitokset',
      COMBINED: 'Yhteensä',
    },
    history: {
      emission: 'Päästötiedot {{minYear}}\u2013{{maxYear}}',
      emissionFactor: 'Päästökerroin {{minYear}}\u2013{{maxYear}}',
      totalEmission: 'CO<1>2</1>-päästöt {{minYear}}\u2013{{maxYear}}',
      fuel: 'Tuotantotiedot {{minYear}}\u2013{{maxYear}}',
    },
  },
  comparison: {
    title: 'Vertaile päästöjä',
    caption: 'Lisää maksimissaan kolme kohdetta vertailuun',
    comparison: 'Päästöjen vertailu',
    compareButton: 'Vertaile',
    clearButton: 'Poista kaikki kohteet',
    emissions: 'Päästötiedot {{year}}',
    fuels: 'Polttoainetiedot {{year}}',
  },
  units: {
    consumption: 'MWh/a',
    emissionFactor: 'kg/MWh',
    emissionFactorCO: 'kgCO\u2082/MWh',
    emissionFactorCOB: 'kgCO<1>2</1>/MWh',
    emissionFactorCompany: 't/TJ',
    districtHeating: 'GWh',
    electricityGeneration: 'GWh',
    fuelConsumption: 'GWh',
    emission: 'kgCO\u2082',
    emissionTon: 'tCO\u2082',
    emissionTonB: 'tCO<1>2</1>',
    surfaceArea: 'm\u00B2',
    power: 'GWh',
    percentage: '%',
  },
  companyForm: {
    preview: {
      publishingSucceeded: 'Julkaisu onnistui! Päivitetyt tiedot näkyvät laskurissa hetken kuluttua',
      hidingSucceeded: 'Päästöt piiloutuvat laskurista hetken kuluttua',
      showingSucceeded: 'Päästöt näkyvät laskurissa hetken kuluttua',
      targetNetwork: 'Esikatseltava verkko',
      targetYear: 'Tiedot vuodelta',
      refresh: 'Hae päivitetyt tiedot',
      publish: 'Julkaise',
      hide: 'Piilota laskurista',
      show: 'Näytä laskurissa',
      noEmissions: 'Ei päästöjä',
      noUnsavedData: 'Ei tallennettavia muutoksia',
    },
    trading: {
      addNewTradesButton: 'Syötä osto- ja myyntitiedot',
      purchaseInformation: 'Oston tiedot',
      saleInformation: 'Myynnin tiedot',
      boughtPower: 'Ostettu kaukolämpö',
      soldPower: 'Myyty kaukolämpö',
      boughtPowerInfo: 'Ostettu kaukolämpö',
      soldPowerInfo: 'Myyty kaukolämpö',
      usedFuels: 'Käytetyt polttoaineet',
      addFuelsRelatedToTrade: 'Lisää kaupankäyntiin liittyvät polttoaineet',
      addNewBoughtFuel: 'Lisää uusi ostettu polttoaine',
      addNewSoldFuel: 'Lisää uusi myyty polttoaine',
      removeSale: 'Poista myyntitapahtuma',
      removePurchase: 'Poista ostotapahtuma',
      noAddedSales: 'Ei lisättyjä myyntitapahtumia',
      noAddedPurchases: 'Ei lisättyjä ostotapahtumia',
      addNewPurchase: 'Lisää uusi osto',
      addNewSale: 'Lisää uusi myynti',
      purchases: 'Ostot',
      sales: 'Myynnit',
      emissionsEnergyMethodInfo: 'Kauppaerän kulutus laskettuna energiamenetelmällä',
      emissionFactorEnergyMethod: 'Päästökerroin (energiamenetelmä)',
      emissionFactorEnergyMethodInfo: 'Kauppaerän päästökerroin laskettuna energiamenetelmällä',
      emissionsEfficiencyMethodInfo: 'Kauppaerän kulutus laskettuna hyödynjakomenetelmällä',
      emissionFactorEfficiencyMethod: 'Päästökerroin (hyödynjakomenetelmä)',
      emissionFactorEfficiencyMethodInfo: 'Kauppaerän päästökerroin laskettuna hyödynjakomenetelmällä',
      searchBar: {
        labelSale: 'Myyty yhtiölle',
        labelPurchase: 'Ostettu yhtiöltä',
        loadingText: 'Ladataan... ',
        noResults: 'Ei tuloksia',
        placeholder: 'Voit hakea kaukolämpöyhtiötä nimellä',
        clear: 'Tyhjennä',
      },
    },
    navigationBar: {
      title: 'Päästölaskuri',
      logout: 'Kirjaudu ulos',
      frontPage: 'Etusivu',
      generalInformation: 'Perustiedot',
      productionAndEmissionInformation: 'Tuotanto- ja päästötiedot',
      tradingInformation: 'Osto- ja myyntitiedot',
      preview: 'Esikatsele ja julkaise',
      user: 'Käyttäjätunnus',
    },
    time: {
      timeSpan: 'Aikaväli',
      startDate: 'Alkamispäiviä',
      endDate: 'Loppumispäivä',
      placeholder: 'pp.kk.vvvv',
    },
    company: {
      name: 'Yhtiön nimi',
      date: 'Tiedot voimassa alkaen',
      inputDataFromYear: 'Syötettävät tiedot',
      dataForYear: 'Tiedot vuodelle',
      dateInfo: 'Tiedot ovat voimassa kyseisestä alkuajankohdasta alkaen',
      companyTitle: 'Yhtiön perustiedot',
      networksTitle: 'Verkot',
      powerPlantsTitle: 'Laitokset',
      areasTitle: 'Paikkakunnat',
      noAddedNetworks: 'Ei lisättyjä verkkoja',
      addYear: 'Lisää vuosi',
      month: 'Kuukausi',
      timePeriod: 'Aikaväli',
      dataType: 'Tietojen tyyppi',
      addNextMonth: 'Lisää seuraava kuukausi',
      alerts: {
        importRealizedYearDataNextYearTitle: 'Vuoden tietojen syöttötapa',
        importRealizedYearDataNextYearContext: 'Syötetäänkö vuoden tiedot kuukausitasolla vai vuositasolla? Vuositason syötössä nykyisen vuoden tiedot voi syöttää myös osittain, jos vuotta on vielä jäljellä.',
        importRealizedYearDataThisYearTitle: 'Vuoden tietojen syöttötapa',
        importRealizedYearDataThisYearContext: 'Syötetäänkö vuoden tiedot kuukausitasolla vai vuositasolla? Vuositason syötössä nykyisen vuoden tiedot voi syöttää myös osittain, jos vuotta on vielä jäljellä.',
        importMonthlyDataTitle: 'Lisää päästötiedot kuukaudelle {{month}}?',
        importMonthlyDataContext: 'Verkkojen tiedot siirretään automaattisesti uudelle kuulle.',
        importFutureDataNextYearTitle: 'Syötä tulevaisuuden ennustetiedot',
        importFutureDataNextYearContext: 'Haluatko syöttää tulevaisuuden ennustetiedot seuraavalle vuodelle? Syötty tapahtuu samalla tavoin kuin toteutuneuden tietojen syötöt.',
        importFutureDataThisYearTitle: 'Syötä tulevaisuuden ennustetiedot',
        importFutureDataThisYearContext: 'Haluatko syöttää tulevaisuuden ennustetiedot tälle vuodelle? Syötty tapahtuu samalla tavoin kuin toteutuneuden tietojen syötöt.',
        labelMonthly: 'Kuukausittain',
        labelYearly: 'Vuosittain',
        labelCancel: 'Peruuta',
        labelYes: 'Kyllä',
        labelAdd: 'Lisää',
      },
    },
    network: {
      name: 'Verkon nimi',
      description: 'Kuvaus (julkinen)',
      descriptionInfo: 'Kuvaus (julkinen)',
      emissionLossPercentage: 'Häviöprosentti',
      emissionLossPercentageInfo: 'Häviöprosentin selitys',
      hasEmissionTrading: 'Verkko kuuluu päästökaupan piiriin',
      hasNoEmissionTrading: 'Verkko ei kuulu päästökaupan piiriin',
      addNetworkButton: 'Lisää uusi verkko',
      removeNetworkButton: 'Poista verkko',
    },
    networkImportDialog: {
      title: 'Tuodaanko edeltävän vuoden verkot pohjaksi valitulle vuodelle?',
      content: 'Edellisen vuoden verkot voidaan tuoda pohjaksi valitulle vuodelle {{year}}.',
    },
    areas: {
      area: 'Paikkakunta',
      distributionPercentage: 'Prosenttiosuus',
      addAreaButton: 'Lisää uusi paikkakunta',
      removeAreaButton: 'Poista paikkakunta',
    },
    plant: {
      CHPPlant: 'CHP-laitos',
      heatingInstallation: 'Lämpölaitos',
      districtHeating: 'Tuotettu kaukolämpö',
      electricityGeneration: 'Tuotettu sähkö',
      processHeat: 'Tuotettu prosessihöyry',
      processHeatInfo: 'Tuotettu prosessihöyry info',
      wasteHeat: 'Hukka- ja ylijäämälämpö',
      districtHeatingInfo: 'Tuotettu ja talteenotettu lämpö (esimerkiksi pumppujen tuottama) syötetään molemmat "tuotettu lämpö" -riville, eli niitä ei erotella toisistaan',
      electricityGenerationInfo: 'Tuotettu sähkö',
      wasteHeatInfo: 'Hukka- ja ylijäämälämpö',
    },
    frontPage: {
      title: 'Tervetuloa!',
    },
    info: {
      addNewInformationButton: 'Syötä tuotanto- ja päästötiedot',
      general: {
        title: 'Tuotanto- ja päästötiedot',
        timeSpan: 'Aikaväli',
        districtHeating: 'Tuotettu kaukolämpö (GWh)',
        processHeat: 'Tuotettu prosessihöyry (GWh)',
        emissionFactor: 'Päästökerroin (tCO\u2082/TJ)',
        totalEmissions: 'Kokonaispäästöt (tCO\u2082)',
      },
      table: {
        networkName: 'Verkko',
        plantName: 'Tuotantolaitos',
        fuel: 'Polttoaine',
        totalAmountInGWh: 'Kulutus (GWh)',
        fuelEmissionFactor: 'Päästökerroin (tCO\u2082/TJ)',
        processHeat: 'Tuotettu prosessihöyry (GWh)',
        districtHeatingGWh: 'Tuotanto kaukolämpö (GWh)',
        electricityGenerationGWh: 'Tuotanto sähkö (GWh)',
        emissions: 'Päästöt kaukolämpö (tCO\u2082)',
        emissionsElectricity: 'Päästöt sähkö (tCO\u2082)',
        emissionsProcessHeat: 'Päästöt prosessihöyry (tCO\u2082)',
        emissionsCombined: 'Päästöt yhteensä (tCO\u2082)',
        emissionFactorDistrictHeating: 'Päästökerroin kaukolämpö (tCO\u2082/TJ)',
        emissionFactorElectricity: 'Päästökerroin sähkö (tCO\u2082/TJ)',
        emissionFactorProcessHeat: 'Päästökerroin prosessihöyry (tCO\u2082/TJ)',
        networksCombined: 'Verkot yhteensä',
      },
    },
    fuel: {
      title: 'Käytetyt polttoaineet',
      fuelsInfo: 'BIO-merkittyjen biopolttoaineiden hiilidioksidipäästöjä ei lasketa Suomen kasvihuonekaasujen kokonaispäästömäärään, joten niiden päästökertoimeksi tulee asettaa 0',
      fuel: 'Polttoaine',
      emissionFactor: 'Päästökerroin',
      consumption: 'Kulutus',
      addFuel: 'Lisää polttoaine',
      removeFuel: 'Poista polttoaine',
      searchBar: {
        clear: 'Tyhjennä',
        loadingText: 'Ladataan...',
        noResults: 'Ei tuloksia',
      },
    },
    plants: {
      name: 'Nimi',
      type: 'Laitostyyppi',
      CHP: 'CHP',
      other: 'Lämpölaitos',
      districtHeatingGWh: 'Kaukolämmön tuotanto',
      districtHeatingGWhInfoText: 'Kaukolämmön tuotanto',
      electricityGenerationGWh: 'Sähkön tuotanto',
      electricityGenerationGWhInfoText: 'Sähkön tuotanto',
      plantDoesntExist: 'Laitosta ei ole olemassa',
      plantHasNoFuels: 'Laitokselle ei ole lisätty polttoaineita',
      addPlantButton: 'Lisää uusi laitos',
      fuelsButton: 'Polttoaineet...',
      fuels: 'Polttoaineet',
      removePlantButton: 'Poista laitos',
      removeFuelButton: 'Poista rivi',
      addFuelButton: 'Lisää uusi polttoaine',
      emissionFactor: 'Päästökerroin',
      useDefault: 'Syötä omat luvut',
      titleAddPlant: 'Lisää uusi laitos',
      titleModifyPlant: 'Muokkaa laitosta',
      searchBar: {
        fuel: 'Polttoaine',
        loadingText: 'Ladataan...',
        placeholder: 'Hae polttoaineen nimeä',
        noResults: 'Hakulauseella ei löytynyt polttoaineita',
      },
      table: {
        name: 'Nimi',
        CHP: 'CHP-laitos',
        yes: 'Kyllä',
        no: 'Ei',
        districtHeatingGWh: 'Lämmöntuotanto (GHw)',
        electricityGenerationGWh: 'Sähköntuotanto (GWh)',
        fuels: 'Polttoaineet (Päästökerroin)',
        EditTitle: 'Muokkaa',
        EditInfo: 'Muokkaa laitoksen tietoja',
        RemoveTitle: 'Poista',
        RemoveInfo: 'Poista laitos',
        TableEmpty: 'Ei laitoksia',
      },
    },
    error: {
      percentageNot100: 'Prosenttiosuuksien summa pitää olla 100 %',
      duplicateName: 'Nimi on jo olemassa',
    },
    dateFormatVisible: 'DD.MM.yyyy',
  },
  common: {
    alerts: {
      unsavedChangesTitle: 'Tallentamattomia muutoksia',
      unsavedChangesContentTextLeaveStill: 'Poistutaanko silti?',
      unsavedChangesContentTextCancelChanges: 'Poistetaanko tallentamattomat muutokset?',
      saveSuccesfull: 'Tallennus onnistui!',
      fillMissingFields: 'Täytä vaaditut kentät',
    },
    buttons: {
      save: 'Tallenna',
      back: 'Takaisin',
      leave: 'Poistu',
      ok: 'Ok',
      cancel: 'Peruuta',
      yes: 'Kyllä',
      no: 'Ei',
      import: 'Tuo',
    },
    dateFormatVisible: 'DD.MM.yyyy',
    dateFormatVisibleHours: 'HH:mm',
    showMore: 'Näytä lisää...',
    showLess: 'Näytä vähemmän',
  },
  admin: {
    users: {
      users: 'Käyttäjätunnukset',
      addUser: 'Lisää käyttäjätunnus',
      editUser: 'Muokkaa käyttäjätunnusta',
      formTitle: 'Luo yhtiölle uusi käyttäjätunnus',
      userContactInformation: 'Käyttäjän yhteystiedot',
      companyInformation: 'Yhtiön tiedot',
      firstName: 'Etunimi',
      lastName: 'Sukunimi',
      name: 'Nimi',
      email: 'Sähköpostiosoite',
      phone: 'Puhelinnumero',
      company: 'Yhtiö',
      userActive: 'Käyttäjätunnus aktiivinen',
      active: 'Aktiivinen',
      noUsers: 'Ei käyttäjätunnuksia',
      removeConfirm: 'Haluatko varmasti poistaa käyttäjätunnuksen {{email}}?',
    },
    companies: {
      companies: 'Yhtiöt',
      name: 'Nimi',
      addCompany: 'Lisää yhtiö',
      companyId: 'Y-tunnus',
      removeConfirm: 'Haluatko varmasti poistaa yhtiön {{company}}?',
      editConfirm: 'Haluatko varmasti muuttaa yhtiön {{nameOld}} nimen nimeksi {{name}}?',
      nameAlreadyExists: 'Nimi on jo olemassa.',
      companyIdAlreadyExists: 'Yhtiön id on jo olemassa',
    },
    navigationBar: {
      title: 'Päästölaskuri',
      logout: 'Kirjaudu ulos',
      frontPage: 'Etusivu',
      manageUsers: 'Käyttäjien hallinta',
      manageCompanies: 'Yhtiöiden hallinta',
      manageNews: 'Uutisten hallinta',
      logs: 'Logitiedot',
    },
    searchBar: {
      label: 'Hae kaukolämpöyhtiö',
      loadingText: 'Ladataan... ',
      noResults: 'Ei tuloksia',
      placeholder: 'Voit hakea kaukolämpöyhtiötä nimellä',
      clear: 'Tyhjennä',
    },
    news: {
      editArticle: 'Muokkaa artikkelia',
      removeArticleButton: 'Poista artikkeli',
      publishNewArticle: 'Julkaise uusi artikkeli',
      expiredArticles: 'Vanhentuneet artikkelit',
      title: 'Otsikko',
      ingress: 'Ingressi',
      category: 'Kategoria',
      author: 'Kirjoittaja',
      subCategory: 'Ala-kategoria',
      expireDate: 'Viimeinen näyttämispäivä',
      articlePicture: 'Artikkelin kuva',
      articleHasArticlePicture: 'Artikkelilla on kuva',
      pictureLink: 'Kuvan linkki',
      pictureCaption: 'Kuvateksti',
      pictureAlt: 'Alt-teksti',
      articleBodyText: 'Uutisen leipäteksti',
      showWritingInstructions: 'Näytä kirjoitusohjeet',
      failedToFetchArticle: 'Virhe: Artikkelia ei voitu hakea palvelimelta',
      inFinnish: 'Suomeksi',
      inSwedish: 'Ruotsiksi',
      inEnglish: 'Englanniksi',
      preview: 'Esikatselu',
      addArticleButton: 'Julkaise artikkeli',
      editArticleButton: 'Tallenna muutokset',
      dialogRemove: {
        content: 'Haluatko varmasti poistaa artikkelin sivulta lopullisesti?',
        yes: 'Kyllä',
        no: 'Ei',
      },
      paragraphInstructions: {
        addNewParagraph: 'Aloita uusi kappale kahden peräkkäisen rivinvaihdon avulla',
        addHeader: 'Lisää otsikko aloittamalla rivi "# " (ilman lainausmerkkejä)',
        addImage: 'Lisää kuva kirjoittamalla ![alt-teksti](linkki "Kuvatesti")',
        italics: 'Kirjoita kursiivista tekstiä kappaleissa kirjoittamalla *teksti* tai _teksti_',
        bold: 'Kirjoita lihavoitua tekstiä kappaleissa kirjoittamalla **teksti** tai __teksti__',
        leading: 'Kirjoita johtavaa tekstiä (lihavoitu ja isolla) kirjoittamalla ***teksti*** tai ___teksti___',
        addLink: 'Kirjoita teksti linkkinä kirjoittamalla [teksti](url)',
        asteriskOrUnderscore: 'Kirjoita * kirjoittamalla \\* ja _ kirjoittamalla \\_',

      },
    },
  },
  errors: {
    fillRequiredFields: 'Täytä vaaditut kentät',
    invalidPhoneNumber: 'Virheellinen puhelinnumero',
    invalidEmail: 'Virheellinen sähköpostiosoite',
    networkImportFailed: 'Verkkojen tuonti epäonnistui. Mikäli tietoja tuotiin, mutta ne ovat puutteelliset, ole yhteydessä ylläpitoon.',
    failedToSaveToDB: 'Muutosten tallentaminen epäonnistui: {{error}}',
    networkError: 'Verkkovirhe. {{code}}',
    networkErrorNoCode: 'Verkkovirhe.',
  },
  warnings: {
    noNetworksAddedForYear: 'Vuodelle ei ole lisätty vielä verkkoja, <2>lisää verkot perustiedoista</2>.',
    noPlantsAddedForYear: 'Vuodelle ei ole lisätty vielä laitoksia, <2>lisää laitokset ensin perustiedoista</2>.',
    noNetworksToPreview: 'Ei esikatseltavia verkkoja, <2>lisää ensin verkkoja perustiedoista</2>.',
  },
  errorBoundary: {
    title: 'Jokin meni vikaan...',
    subtitle: 'Päästölaskurissa tapahtui virhe. Yritä ladata sivu uudelleen. Jos uudelleen lataus ei toimi palaa alkuun.',
    button: 'Lataa sivu uudestaan',
  },
  news: {
    photo: 'Kuva',
    edited: 'Päivitetty',
    failedToFetchArticleList: 'Virhe: Uutisten haku palvelimelta epäonnistui.',
    failedToFetchArticle: 'Virhe: Uutisen haku palvelimelta epäonnistui.',
    noArticlesText: 'Tämä sivu on tyhjä.',
  },
  months: {
    january: 'Tammikuu',
    february: 'Helmikuu',
    march: 'Maaliskuu',
    april: 'Huhtikuu',
    may: 'Toukokuu',
    june: 'Kesäkuu',
    july: 'Heinäkuu',
    august: 'Elokuu',
    september: 'Syyskuu',
    october: 'Lokakuu',
    november: 'Marraskuu',
    december: 'Joulukuu',
    wholeYear: 'Koko vuosi',
  },
};

export type LanguageStrings = typeof baseStrings;
export default baseStrings;
