import i18n, { TFunctionResult } from 'i18next';

import { StepperState } from 'types/wizard';
import getLocale from 'utils/getLocale';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initialSteps = () => ([i18n.t('main:wizard.locationStep'), i18n.t('main:wizard.processStep')]);

const getSteps = (state: StepperState): string[] => {
  const firstSteps = initialSteps();

  if (state.activeStep < 2) {
    return firstSteps.concat([i18n.t('main:wizard.inputStep')]);
  }

  switch (state.processType) {
    case 'selection':
      return firstSteps.concat([
        i18n.t('main:wizard.homeStep'),
        i18n.t('main:wizard.constructionYearStep'),
        i18n.t('main:wizard.surfaceAceaStep'),
      ]);
    case 'consumption':
      return firstSteps.concat([i18n.t('main:wizard.consumptionStep')]);
    default:
      throw new Error('Invalid process type');
  }
};

export const getStepAnswer = (state: StepperState, index: number): string => {
  let result: string | number | TFunctionResult |undefined;

  switch (index) {
    case 0: {
      result = state.selectedTarget?.name;
      break;
    }
    case 1: {
      if (state.activeStep > 1) {
        result = i18n.t(`main:wizard.processType.${state.processType}`, '');
      }
      break;
    }
    case 2: {
      if (state.activeStep > 2) {
        result = state.processType === 'consumption'
          ? `${state.inputs.consumption?.toLocaleString(getLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0} ${i18n.t('main:units.consumption', '')}`
          : i18n.t(`main:wizard.homeType.${state.inputs.homeType}`, '');
      }
      break;
    }
    case 3: {
      if (state.activeStep > 3) {
        result = state.inputs.constructionYear;
      }
      break;
    }
    case 4: {
      if (state.activeStep > 4) {
        result = `${state.inputs.surfaceArea?.toLocaleString(getLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${i18n.t('main:units.surfaceArea', '')}`;
      }
      break;
    }
    default:
      break;
  }

  return result ? `: ${result}` : '';
};

export const canContinue = (state: StepperState): boolean => {
  if (state.activeStep === 0) return !!state.selectedTarget;
  if (state.activeStep === 1) return !!state.processType;
  return true;
};

export default getSteps;
