import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import AdminTabs from 'Components/Admin/AdminTabs';
import { RouteComponentProps } from 'react-router-dom';
import AlertBox from 'Components/Common/AlertBox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    height: '100%',
  },
}));

const AdminLayout: FC<RouteComponentProps> = ({ children, location }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AdminTabs location={location}>
        {children}
      </AdminTabs>
      <AlertBox />
    </div>
  );
};

export default AdminLayout;
