import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import fi from './fi';
import en from './en';
import sv from './sv';

const resources = {
  fi, en, sv,
};

i18n.use(initReactI18next).init({
  fallbackLng: 'fi',
  supportedLngs: ['fi', 'en', 'sv'],
  debug: false,
  ns: [
    'main',
  ],
  resources,
});

export default resources;
