/* eslint-disable no-param-reassign */
import { gql } from 'urql';
import moment from 'moment';
import { RssItem } from 'types/rss';
import { useClient } from 'graphql/client';
import { TargetType } from 'types/calculation';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface PublicState {
  comparison: {
    id: string,
    name: string,
    type: TargetType
    companyId?: string,
  }[];
  showComparison: boolean,
  rss?: RssItem[],
}

const RSS_QUERY = gql`
  query Rss {
    rss {
      text
      date
      header
      subHeader
    }
  }
`;

export const fetchRss = createAsyncThunk(
  'public/fetchRss',
  async () => {
    const result = await useClient().query<{ rss: RssItem[] }>(
      RSS_QUERY, undefined, { requestPolicy: 'network-only' },
    ).toPromise();

    const { error, data } = result;
    if (error) console.error(result.error);

    data?.rss.sort(
      (a, b) => moment(b.date, 'DD-MM-YYYY').valueOf() - moment(a.date, 'DD-MM-YYYY').valueOf(),
    );

    return data?.rss || [];
  },
);

const initialState: PublicState = {
  comparison: [],
  showComparison: true,
};

const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    addToComparison(state, action) {
      const {
        id,
        name,
        type,
        companyId,
      } = action.payload;

      if (!state.comparison.find((x) => x.id === id)) {
        state.comparison.push({
          id,
          name,
          type,
          companyId,
        });
        state.showComparison = true;
      }
    },
    removeFromComparison(state, action) {
      const { id } = action.payload;
      const existing = state.comparison.findIndex((c) => c.id === id);
      if (existing > -1) {
        state.comparison.splice(existing, 1);
      }
    },
    removeAllFromComparison(state) {
      state.comparison = [];
    },
    setShowComparison(state, action) {
      state.showComparison = false;
      state.showComparison = action.payload.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRss.fulfilled, (state, action) => {
      state.rss = action.payload;
    });
  },
});

export const {
  addToComparison,
  removeFromComparison,
  removeAllFromComparison,
  setShowComparison,
} = publicSlice.actions;

export default publicSlice.reducer;
