import React, { FC } from 'react';

import {
  Box,
  makeStyles,
  Theme,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    backgroundColor: 'inherit',
  },
}));

const LoadingScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
