import { LanguageStrings } from 'i18n/fi/main';

const svStrings:LanguageStrings = {
  routes: {
    home: '/sv',
    whatCalculator: '/sv/vadarutslappsraknaren',
    background: '/sv/bakgrund',
    login: '/sv/inloggning',
    development: '/sv/utveckling',
    news: '/sv/aktuellt',
    methods: '/sv/beräkningssätt',
    emissions: '/sv/utslapp',
    wizard: '/sv/berakna',
    compare: '/sv/jamforelse',
    calculateYourEmissions: '/sv/beraknadinautslapp',
  },
  background: `Utsläppsräknaren för fjärrvärme är ett samprojekt mellan Lokalkraft rf och finländska \
  fjärrvärmebolag. Räknarens syfte är att erbjuda fjärrvärmekunder och aktörer som är intresserade \
  av fjärrvärme en transparent och jämförbar utsläppsräknare för fjärrvärme.\n\n\
  \
  Det finns många utsläppsräknare som riktar sig till konsumenter och företag, men det fanns ingen \
  tjänst som fokuserar på utsläppen från fjärrvärme.\n\n\
  \
  De tidigare räknarna som baserat sig på de genomsnittliga utsläppen från fjärrvärme har gett samma \
  utsläppsvärden oavsett vilket bränsle fjärrvärmen producerats med. De genomsnittliga utsläppen har \
  varit ett förenklat sätt att beskriva situationen, eftersom produktionssätten för fjärrvärme är \
  mycket varierande på olika orter. Den statistik som hittills använts för att beräkna utsläppen har \
  ibland uppdaterats väldigt sent. Dessutom har de data som levererats till statistiken varit utspridd \
  på olika platser och även angiven på sätt som inte är jämförbara sinsemellan (t.ex. för \
  produktionsanläggningar som omfattas resp. inte omfattas av utsläppshandeln).\n\n\
  \
  Utsläppsräknaren för fjärrvärme råder bot på de här bristerna. Den har programmerats att på \
  grundval av kundens hemort hämta koldioxidutsläppen för den fjärrvärme som producerats till \
  fjärrvärmenätet på orten i fråga. Utsläppsräknaren omfattar cirka 99 % av landets fjärrvärmedata \
  och detaljerade data på månadsnivå matas för närvarande in i räknaren av över 75 % av alla \
  fjärrvärmebolag i Finland. Antalet ökar också hela tiden. Öppenhet vid insamlingen av data och \
  transparens i fråga om orternas fjärrvärmenät är av central betydelse i räknaren.\n\n\
  \
  Lokalkraft rf började arbetet med att ta fram räknaren våren 2020. Bakgrundsutredningen för \
  räknaren finns <2>här</2>.\n\n\
  \
  De parametrar som använts i utsläppsräknaren för fjärrvärme är jämförbara och följer \
  Statistikcentralens officiella metoder och parametrar. Räknaren beaktar också utsläppsdata \
  som grundar sig på mer detaljerad lagstiftning, så som utsläppshandeln. Vid anläggningar \
  som omfattas av utsläppshandeln görs mätningar och verifieringar som i många avseenden \
  är noggrannare än Statistikcentralens. De här verifieringarna följer mer detaljerad \
  lagstiftning och preciserar därmed beräkningen av koldioxidutsläppen från produktionen. \
  Räknaren kommer att ange om en anläggning omfattas av utsläppshandeln eller inte.\n\n\
  \
  I statistiken har utsläppen traditionellt beskrivits kalenderårsvis bakåt i tiden. Bolagen \
  som är med i utsläppsräknaren för fjärrvärme kan mata in produktionsdata månadsvis, och då \
  får slutanvändarna aktuellare data om den realiserade fjärrvärmeproduktionen.\n\n\
  \
  Styrgruppen för arbetet med utsläppsräknaren har bestått av Finlands Miljöcentral, Kommunförbundet, \
  Statistikcentralen, Energimyndigheten, Motiva, Finsk Energiindustri rf samt Palveleva Kaukolämpö \
  FinDHC ry. För det tekniska utförandet och underhållet av utsläppsräknaren står Akamon Innovations \
  Oy. Utsläppsräknaren för fjärrvärme öppnades för allmänheten 11.2.2022. Via de öppna gränssnitten \
  kan alla aktörer som tar fram räknare för koldioxidutsläpp utnyttja data från utsläppsräknaren för ${''}\
  fjärrvärme i sina egna räknare.`,

  development: `Utsläppsräknaren för fjärrvärme utvecklas i ett team som består av tekniska experter \
  från fjärrvärmebolagen. Vid utvecklingen beaktas dels förbättringsönskemål från fjärrvärmeanvändarna \
  och dem som behöver utsläppsdata, dels ändringsbehov som föranleds av lagstiftning samt \
  fjärrvärmeproducenternas behov. Det tekniska expertteamet består av experter på olika verksamhetsområden.\n\n\
  \
  I teamet ingår experter på miljörapportering, försäljning, teknik och produktion samt installation och datasystem. \
  Förbättringsförslag kan skickas till expertteamet via den här <2>e-postlänken</2>. Utvecklingsarbetet samordnas \
  av Lokalkraft rf. Som projektchef fungerar Jari Nykänen. I frågor som rör utvecklingen av utsläppsräknaren för ${''}\
  fjärrvärme samt andra frågor kan du också kontakta Lokalkraft: jari.nykanen@paikallisvoima.fi`,

  whatCalculator: `Som namnet säger beräknar utsläppsräknaren koldioxidutsläppen från fjärrvärme för enskilda \
  orter och enskilda fjärrvärmenät och presenterar värdena noggrant och enhetligt. Det kostar ingenting att \
  använda tjänsten.\n\nUtgångsinformationen, alltså information om fjärrvärmenäten, matas in i systemet separat \
  för varje nät. Varje nät får en kommunuppgift, enligt vilken användaren får information från den egna hemkommunen. \
  Det finns fjärrvärmenät som sträcker sig över flera kommuners områden samt kommuner som har fler än ett \
  fjärrvärmenät. Det är bra om användaren vet hos vilket energibolag och fjärrvärmenät han eller hon är kund.\n\n\
  \
  I räknaren matar energibolagen in sina produktionsfördelningar, det vill säga vilka bränslen och andra \
  produktionsmetoder de använder (t.ex. värme som producerats med värmepumpar). Informationen matas in i \
  enlighet med Statistikcentralens officiella och standardiserade definitioner. De använda bränslena får \
  Statistikcentralens utsläppskoefficienter för bränslen. Om ett energibolags produktionsanläggning är \
  med i EU:s utsläppshandel används de mätmetoder som beskrivs i den plan för övervakning av utsläppen \
  som hör till utsläppstillståndet, till exempel analyserade utsläppskoefficienter för bränslen. Endast \
  dessa noggranna och uppmätta utgångsdata kan användas som ersättning för Statistikcentralens koefficienter. \
  Energibolagen kan mata in data i räknaren månadsvis, enligt uppgifterna för den närmast föregående \
  realiserade månaden. De data som statistikförts är cirka ett år gammal, på grund av kraven på kontroll \
  och publicering av statistiken.\n\n\
  \
  I räknaren finns tillgång till utsläppsdata för de fem senaste åren, samt bolagens produktionsfördelningar \
  för fjärrvärme. Med hjälp av data kan användaren beräkna utsläppen från sin egen fjärrvärmeförbrukning \
  med hjälp av utsläppskoefficienten för det senaste hela året, antingen genom att mata in uppgifter om sin ${''}\
  förbrukning eller genom att uppskatta dem på grundval av hustyp, yta och byggår.`,
  methods: {
    content: `Datainnehållet i utsläppsräknaren baserar sig på officiella data som levererats av bolagen själva.
 
    Utsläppsdata för åren 2016–2020 har beräknats utifrån Finsk Energiindustri rf:s statistiska publikationer \
    som baserar sig på årligen insamlade data. I fjärrvärmestatistiken har data publicerats kommunvis per \
    fjärrvärmeförsäljare. De statistiska publikationerna finns på Finsk Energiindustris webbplats:
    <2>https://energia.fi/uutishuone/materiaalipankki/kaukolampotilasto.html#material-view</2>
     
    De specifika utsläppskoefficienter för bränslen som använts vid beräkningen har beräknats utifrån den \
    Bränsleklassificering som Statistikcentralen årligen publicerar. Bränsleklassificeringarna finns på \
    Statistikcentralens webbplats:
    <6>https://www.stat.fi/tup/khkinv/khkaasut_polttoaineluokitus.html</6>
     
    Eftersom data i Finsk Energiindustris statistiska publikationer är på kommunnivå, innebär det att även \
    beräkningen av utsläppskoefficienter och utsläpp på grundval av dessa data görs per försäljare, kommunvis \
    och enligt produktionsmetod. Vid beräkningen beaktas utöver fjärrvärmeförsäljarens egen fjärrvärmeproduktion \
    även fjärrvärmehandel försäljarna emellan. De CO<9>2</9>-utsläpp som uppkommit vid produktionen av det sålda \
    fjärrvärmepartiet inräknas i köparens utsläpp på grundval av de genomsnittliga kalkylerade utsläppen från \
    försäljarens fjärrvärmeproduktion.
     
    Från och med ingången av 2021 har fjärrvärmeförsäljarna möjlighet att mata in data om sin sålda \
    fjärrvärmeproduktion i räknaren separat för varje produktionsanläggning. Räknaren ger möjlighet att mata \
    in data för de tidsperioder försäljaren väljer, till exempel månadsvis. Den fjärrvärmehandel försäljarna \
    emellan som matas in i räknaren innehåller information om hur det sålda fjärrvärmepartiet producerats. \
    Utifrån de data som matats in beräknar räknaren utsläppskoefficienter och utsläpp för varje enskild \
    anläggning och på grundval av exakta data som matats in om fjärrvärmehandel.
     
    Farligt avfall (tidigare kallat problemavfall) beräknas enligt noll utsläpp.

    Resultaten som beräknats per anläggning kan skilja sig en aning från resultaten som beräknats per kommun.
    `,
    usedCalculationMethods: 'Beräkningsformler som använts i utsläppsräknaren',
  },
  appbar: {
    home: 'Ingångssida',
    whatCalculator: 'Vad är utsläppsräknaren?',
    background: 'Bakgrund',
    companies: 'Bolag som medverkar',
    development: 'Utveckling',
    methods: 'Beräkningssätt',
    news: 'Aktuellt',
    company: 'Till bolaget',
    admin: 'Admin',
    login: 'Inloggning',
  },
  pageTitles: {
    home: 'Lokalkraft | Utsläppsräknaren för fjärrvärme',
    whatCalculator: 'Vad är utsläppsräknaren? - Utsläppsräknaren för fjärrvärme | Lokalkraft',
    background: 'Bakgrund - Utsläppsräknaren för fjärrvärme | Lokalkraft',
    development: 'Utveckling - Utsläppsräknaren för fjärrvärme | Lokalkraft',
    methods: 'Beräkningssätt - Utsläppsräknaren för fjärrvärme | Lokalkraft',
    results: '{{target}} - CO2-fjärrvärmeutsläpp - Utsläppsräknaren för fjärrvärme | Lokalkraft',
    wizard: 'Beräkna dina egna utsläpp - Utsläppsräknaren för fjärrvärme | Lokalkraft',
    news: 'Aktuellt - Utsläppsräknaren för fjärrvärme | Lokalkraft',
  },
  accounts: {
    changePasswordButton: 'Byt lösenord',
    changePasswordTitle: 'Byt lösenord för användarnamnet',
    changePasswordHelper: 'Lösenordet ska bestå av minst 10 tecken, däribland minst en stor bokstav, en liten bokstav, en siffra och ett specialtecken.',
    oldPassword: 'Gammalt lösenord',
    newPassword: 'Nytt lösenord',
    newPasswordAgain: 'Nya lösenordet en gång till',
    errorTextLength: 'Lösenordet ska bestå av minst 10 tecken',
    errorTextCharacters: 'Lösenordet ska innehålla minst en stor bokstav, en liten bokstav, en siffra och ett specialtecken',
    errorTextNotSame: 'De nya lösenorden måste vara lika',
    errorTextNotEmpty: 'Fältet kan inte vara tomt',
    errorTextMustDifferFromOld: 'Det nya lösenordet måste vara ett annat än det gamla lösenordet',
    forgotPassword: 'Jag har glömt mitt lösenord',
    forgotPasswordTitle: 'Vi skickar en länk till din e-post där du kan byta ditt lösenord',
    userDoesNotExist: 'Användarnamn saknas för den e-postadress du angett',
    wrongUsernameAndOrPassword: 'Fel e-postadress och/eller lösenord',
    code: 'Kod',
    username: 'Användarnamn',
    password: 'Lösenord',
    passwordChangedSuccesfully: 'Lösenordet har ändrats!', // Used google translate...
    returnToLoginPage: 'Återgå till inloggningssidan', // Used Google translate...
    userNotFoundException: 'Det gick inte att byta lösenord, användaren finns inte', // Used Google translate...
    codeMismatchException: 'Det gick inte att byta lösenord, ogiltig kod', // Used Google translate...
    codeDeliveryPhone: 'Koden för att ändra lösenordet levererades till nummer {{phone}}.', // Used Google translate...
    codeDeliveryEmail: 'Koden för att ändra lösenordet levererades till e-post {{email}}.', // Used Google translate...
    login: 'Logga in',
    logout: 'Logga ut',
    email: 'E-postadress',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefonnummer',
    companyName: 'Bolagets namn',
    title: 'Utsläppsräknaren',
    sendLinkButton: 'Sänd',
  },
  header: 'Välkommen att använda utsläppsräknaren för fjärrvärme!',

  subHeader: `I utsläppsräknaren för fjärrvärme ser du koldioxidutsläppen för det fjärrvärmebolag \
  eller den kommun du önskar, kan jämföra olika kommuners och/eller fjärrvärmebolags koldioxidutsläpp ${''}\
  och beräkna dina egna koldioxidutsläpp.`,

  subHeaderMore: 'Räknaren anger utsläppskoefficienten och utsläppen från fjärrvärmeproduktionen för varje enskilt nät, och dessa kan skilja sig från den fjärrvärmeproduktion som en enskild kund separat avtalat om med fjärrvärmeleverantören. Från många fjärrvärmeleverantörer har kunderna möjlighet att köpa fjärrvärme som producerats med utsläppsfria produktionsmetoder.',
  searchBar: {
    label: 'Sök fjärrvärmebolag eller kommun',
    placeholder: 'Du kan söka på fjärrvärmebolagets eller kommunens namn',
    clear: 'Töm',
    loadingText: 'Hämtar...',
    noResults: 'Inga resultat',
    groups: {
      city: 'Kommuner',
      company: 'Fjärrvärmenät',
    },
  },
  searchBarNetwork: {
    label: 'Sök fjärrvärmebolag',
    placeholder: 'Du kan söka på fjärrvärmebolagets namn',
  },
  searchBarInfo: 'Kontrollera fjärrvärmebolagets eller kommunens utsläpp.',
  buttonInfo: 'Om du är fjärrvärmeanvändare kan du också beräkna dina egna utsläpp.',
  calculateEmissionButton: 'Beräkna dina egna utsläpp',
  returnHomeButton: 'Till ingångssidan',
  wizard: {
    locationStep: 'Fjärrvärmebolag',
    processStep: 'Utgångsdata',
    inputStep: 'Inmatning av uppgifter',
    homeStep: 'Hustyp',
    constructionYearStep: 'Byggår',
    surfaceAceaStep: 'Yta',
    consumptionStep: 'Värmeförbrukning',
    consumption: 'Värmeförbrukning per år',
    constructionYear: 'Husets byggår',
    surfaceArea: 'Bostadsyta',
    nextButton: 'Nästa',
    backButton: 'Tillbaka',
    calculateButton: 'beräkna dina utsläpp',
    personalResultsTitle: 'Dina fjärrvärmeutsläpp',
    processType: {
      consumption: 'Jag känner till min värmeförbrukning',
      selection: 'Jag känner inte till min värmeförbrukning',
    },
    homeType: {
      HOUSE: 'Egnahemshus',
      TERRACED_HOUSE: 'Radhus',
      FLAT: 'Flervåningshus',
    },
  },
  result: {
    headerNetwork: 'Fjärrvärmenätets utsläpp',
    headerArea: 'Kommuns utsläpp',
    noResultNetwork: 'Laskurissa ei ole tällä hetkellä päästöjä verkolle {{name}}',
    noResultArea: 'Laskurissa ei ole tällä hetkellä päästöjä kunnalle {{name}}',
    subHeader: 'Beräknade utsläpp för år {{year}}',
    emissionFactor: 'Utsläppskoefficient',
    emission: 'Koldioxidutsläpp',
    showInfo: 'Visa utsläppens utveckling',
    hideInfo: 'Dölj utsläppens utveckling',
    includeNetworkLosses: 'Vid beräkningen beaktas nätförluster',
    addToComparison: 'Lägg till i jämförelsen',
    calculationMethod: 'Metod för beräkning av utsläppen',
    calculationMethodInfoTitle: 'Mer information om beräkningsmetoderna',
    calculationMethodInfoBody: 'Utsläppen från värmeproduktionen och utsläppskoefficienterna kan granskas med flera olika metoder. I den här räknaren används två av de vanligaste sätten:\n\nVid nyttofördelningsmetoden anses den höga verkningsgrad som nås vid samproduktion av värme och el vara till nytta för vardera, både el- och värmeproduktionen. Vid nyttofördelningsmetoden beaktas verkningsgraderna för energi som producerats genom särproduktion av el och värme samt den producerade mängden energi. Bränslena och utsläppen fördelas i förhållande till produktionsformernas bränsleförbrukningar.\n\nEnergimetoden är den enklare beräkningsmetoden. Vid energimetoden fördelas bränslena och utsläppen i förhållande till de producerade energierna. Om till exempel 70 % av den totala producerade energin är värme och 30 % el, anser man att 70 % av bränslena har gått till värmeproduktion och 30 % till elproduktion.\n\nLäs mer om de använda metoderna <2>här</2>.',
    fuels: 'Produktionsfördelning',
    realized: 'Uppfyllt',
    planned: 'Planen',
    predictedYear: 'Prognos för slutet av året',
    predictionLegend: 'Prognos',
    calculationMethods: {
      ENERGY: 'Energimetoden',
      EFFICIENCY: 'Nyttofördelningsmetoden',
    },
    productionType: 'Näytä tiedot kohteelle',
    productionTypes: {
      DISTRICT_HEATING: 'Kaukolämpö',
      ELECTRICITY: 'Sähkö',
      PROCESS_HEAT: 'Prosessihöyry',
      FUELS: 'Polttoaineet',
      PLANTS: 'Laitokset',
      COMBINED: 'Yhteensä',
    },
    history: {
      emission: 'Utsläppsdata {{minYear}}\u2013{{maxYear}}',
      emissionFactor: 'Utsläppskoefficient {{minYear}}\u2013{{maxYear}}',
      totalEmission: 'CO<1>2</1>-utsläpp {{minYear}}\u2013{{maxYear}}',
      fuel: 'Produktionsdata {{minYear}}\u2013{{maxYear}}',
    },
  },
  comparison: {
    title: 'Jämför utsläpp',
    caption: 'Lägg till högst tre objekt i jämförelsen',
    comparison: 'Jämförelse av utsläpp',
    compareButton: 'Jämför',
    clearButton: 'Töm alla objekt',
    emissions: 'Utsläppsdata {{year}}',
    fuels: 'Bränsledata {{year}}',
  },
  units: {
    consumption: 'MWh/a',
    emissionFactor: 'kg/MWh',
    emissionFactorCO: 'kgCO\u2082/MWh',
    emissionFactorCOB: 'kgCO<1>2</1>/MWh',
    emissionFactorCompany: 't/TJ',
    districtHeating: 'GWh',
    electricityGeneration: 'GWh',
    fuelConsumption: 'GWh',
    emission: 'kgCO\u2082',
    emissionTon: 'tCO\u2082',
    emissionTonB: 'tCO<1>2</1>',
    surfaceArea: 'm\u00B2',
    power: 'GWh',
    percentage: '%',
  },
  companyForm: {
    preview: { // In English for now (no localization options in company view anyway)
      publishingSucceeded: 'Publishing succeeded! Updated information will show up in the calculator in a moment',
      hidingSucceeded: 'Hiding emissions succeeded! Emissions will be hidden from the public calculator in a moment',
      showingSucceeded: 'Showing emissions succeeded! Emissions will be shown in the calculator in a moment',
      targetNetwork: 'Network to preview',
      targetYear: 'Target year',
      refresh: 'Refresh',
      publish: 'Publish',
      hide: 'Hide from the calculator',
      show: 'Show in the calculator',
      noEmissions: 'No emissions found',
      noUnsavedData: 'No unsaved data',
    },
    trading: {
      addNewTradesButton: 'Lägg till nya köp- och försäljningsuppgifter',
      purchaseInformation: 'Uppgifter om köp',
      saleInformation: 'Uppgifter om försäljning',
      boughtPower: 'Köpt fjärrvärme',
      soldPower: 'Såld fjärrvärme',
      boughtPowerInfo: 'Köpt fjärrvärme',
      soldPowerInfo: 'Såld fjärrvärme',
      usedFuels: 'Använda bränslen',
      addFuelsRelatedToTrade: 'Lägg till bränslen som hänför sig till handeln',
      addNewBoughtFuel: 'Lägg till nytt köpt bränsle',
      addNewSoldFuel: 'Lägg till nytt sålt bränsle',
      removeSale: 'Ta bort säljtransaktion',
      removePurchase: 'Ta bort köptransaktion',
      noAddedSales: 'Inga tillagda säljtransaktioner',
      noAddedPurchases: 'Inga tillagda köptransaktioner',
      addNewPurchase: 'Lägg till nytt köp',
      addNewSale: 'Lägg till ny försäljning',
      purchases: 'Köp',
      sales: 'Försäljningar',
      emissionsEnergyMethodInfo: 'Handelspartiets förbrukning beräknad med energimetoden',
      emissionFactorEnergyMethod: 'Utsläppskoefficient (energimetoden)',
      emissionFactorEnergyMethodInfo: 'Handelspartiets utsläppskoefficient beräknad med energimetoden',
      emissionsEfficiencyMethodInfo: 'Handelspartiets förbrukning beräknad med nyttofördelningsmetoden',
      emissionFactorEfficiencyMethod: 'Utsläppskoefficient (nyttofördelningsmetoden)',
      emissionFactorEfficiencyMethodInfo: 'Handelspartiets utsläppskoefficient beräknad med nyttofördelningsmetoden',
      searchBar: {
        labelSale: 'Sålt till bolaget',
        labelPurchase: 'Köpt från bolaget',
        loadingText: 'Hämtar... ',
        noResults: 'Inga resultat',
        placeholder: 'Du kan söka fjärrvärmebolag på namn',
        clear: 'Töm',
      },
    },
    navigationBar: {
      title: 'Utsläppsräknaren',
      logout: 'Logga ut',
      frontPage: 'Ingångssida',
      generalInformation: 'Basinformation',
      productionAndEmissionInformation: 'Produktions- och utsläppsinformation',
      tradingInformation: 'TODO',
      preview: 'TODO',
      user: 'Användarnamn',
    },
    time: {
      timeSpan: 'Period',
      startDate: 'Startdatum',
      endDate: 'Slutdatum',
      placeholder: 'pp.kk.vvvv',
    },
    company: {
      name: 'Bolagets namn',
      date: 'Informationen gäller från och med',
      inputDataFromYear: 'Informationen från',
      dataForYear: 'Informationen för år',
      dateInfo: 'Informationen gäller från och med ifrågavarande startdatum',
      companyTitle: 'Basinformation om bolaget',
      networksTitle: 'Nät',
      powerPlantsTitle: 'Anläggningar',
      areasTitle: 'Orter',
      noAddedNetworks: 'Inga tillagda nät',
      addYear: 'Lisää vuosi',
      month: 'Kuukausi',
      timePeriod: 'Aikaväli',
      dataType: 'Tietojen tyyppi',
      addNextMonth: 'Lisää seuraava kuukausi',
      alerts: {
        importRealizedYearDataNextYearTitle: 'Vuoden tietojen syöttötapa',
        importRealizedYearDataNextYearContext: 'Syötetäänkö vuoden tiedot kuukausitasolla vai vuositasolla? Vuositason syötössä nykyisen vuoden tiedot voi syöttää myös osittain, jos vuotta on vielä jäljellä.',
        importRealizedYearDataThisYearTitle: 'Vuoden tietojen syöttötapa',
        importRealizedYearDataThisYearContext: 'Syötetäänkö vuoden tiedot kuukausitasolla vai vuositasolla? Vuositason syötössä nykyisen vuoden tiedot voi syöttää myös osittain, jos vuotta on vielä jäljellä.',
        importMonthlyDataTitle: 'Lisää päästötiedot kuukaudelle {{month}}?',
        importMonthlyDataContext: 'Verkkojen tiedot siirretään automaattisesti uudelle kuulle.',
        importFutureDataNextYearTitle: 'Syötä tulevaisuuden ennustetiedot',
        importFutureDataNextYearContext: 'Haluatko syöttää tulevaisuuden ennustetiedot seuraavalle vuodelle? Syötty tapahtuu samalla tavoin kuin toteutuneuden tietojen syötöt.',
        importFutureDataThisYearTitle: 'Syötä tulevaisuuden ennustetiedot',
        importFutureDataThisYearContext: 'Haluatko syöttää tulevaisuuden ennustetiedot tälle vuodelle? Syötty tapahtuu samalla tavoin kuin toteutuneuden tietojen syötöt.',
        labelMonthly: 'Kuukausittain',
        labelYearly: 'Vuosittain',
        labelCancel: 'Peruuta',
        labelYes: 'Kyllä',
        labelAdd: 'Lisää',
      },
    },
    network: {
      name: 'Nätets namn',
      description: 'Beskrivning (offentlig)',
      descriptionInfo: 'Beskrivning (offentlig)',
      emissionLossPercentage: 'Förlustprocent',
      emissionLossPercentageInfo: 'Förklaring av förlustprocenten',
      hasEmissionTrading: 'Nätet omfattas av utsläppshandeln',
      hasNoEmissionTrading: 'Nätet omfattas inte av utsläppshandeln',
      addNetworkButton: 'Lägg till nytt nät',
      removeNetworkButton: 'Ta bort nät',
    },
    networkImportDialog: {
      title: 'Är du säkert att du vill importera näten från förre år?',
      content: 'Näten kan importeras från förre år som en basis för utvald åren {{ year }}.',
    },
    areas: {
      area: 'Ort',
      distributionPercentage: 'Procentandel',
      addAreaButton: 'Lägg till ny ort',
      removeAreaButton: 'Ta bort ort',
    },
    plant: {
      CHPPlant: 'CHP-anläggning',
      heatingInstallation: 'Värmeverk',
      districtHeating: 'Producerad fjärrvärme',
      electricityGeneration: 'Producerad el',
      processHeat: 'Producerad processånga',
      processHeatInfo: 'Producerad processånga info',
      wasteHeat: 'Spill- och överskottsvärme',
      districtHeatingInfo: 'Producerad och tillvaratagen värme (som t.ex. producerats med pumpar) matas vardera in på raden "producerad värme", de separeras alltså inte från varandra',
      electricityGenerationInfo: 'Producerad el',
      wasteHeatInfo: 'Spill- och överskottsvärme',
    },
    frontPage: {
      title: 'Välkommen!',
    },
    info: {
      addNewInformationButton: 'Lägg till ny produktions- och utsläppsinformation',
      general: {
        title: 'Produktions- och utsläppsinformation',
        timeSpan: 'Period',
        districtHeating: 'Producerad fjärrvärme (GWh)',
        processHeat: 'Producerad processånga (GWh)',
        emissionFactor: 'Utsläppskoefficient (tCO\u2082/TJ)',
        totalEmissions: 'Totala utsläpp (tCO\u2082)',
      },
      table: {
        networkName: 'Nät',
        plantName: 'Produktionsanläggning',
        fuel: 'Bränsle',
        totalAmountInGWh: 'Förbrukning (GWh)',
        fuelEmissionFactor: 'Utsläppskoefficient (tCO\u2082/TJ)',
        processHeat: 'Producerad processånga (GWh)',
        districtHeatingGWh: 'Producerad fjärrvärme (GWh)',
        electricityGenerationGWh: 'Tuotanto sähkö (GWh)',
        emissions: 'Utsläpp (tCO\u2082)',
        emissionsElectricity: 'Päästöt sähkö (tCO\u2082)',
        emissionsProcessHeat: 'Päästöt prosessihöyry (tCO\u2082)',
        emissionsCombined: 'Päästöt yhteensä (tCO\u2082)',
        emissionFactorDistrictHeating: 'Päästökerroin kaukolämpö (tCO\u2082/TJ)',
        emissionFactorElectricity: 'Päästökerroin sähkö (tCO\u2082/TJ)',
        emissionFactorProcessHeat: 'Päästökerroin prosessihöyry (tCO\u2082/TJ)',
        networksCombined: 'Verkot yhteensä (tCO\u2082)',
      },
    },
    fuel: {
      title: 'Använda bränslen',
      fuelsInfo: 'Koldioxidutsläppen från BIO-märkta biobränslen räknas inte med i Finlands totala växthusgasutsläpp och ska därför ges utsläppskoefficienten 0',
      fuel: 'Bränsle',
      emissionFactor: 'Utsläppskoefficient',
      consumption: 'Förbrukning',
      addFuel: 'Lägg till bränsle',
      removeFuel: 'Ta bort bränsle',
      searchBar: {
        clear: 'Töm',
        loadingText: 'Hämtar...',
        noResults: 'Inga resultat',
      },
    },
    plants: {
      name: 'Namn',
      type: 'Typ av anläggning',
      CHP: 'CHP',
      other: 'Värmeverk',
      districtHeatingGWh: 'Fjärrvärmeproduktion',
      districtHeatingGWhInfoText: 'Fjärrvärmeproduktion',
      electricityGenerationGWh: 'Elproduktion',
      electricityGenerationGWhInfoText: 'Elproduktion',
      plantDoesntExist: 'Anläggningen finns inte',
      plantHasNoFuels: 'Inga bränslen har lagts till för anläggningen',
      addPlantButton: 'Lägg till ny anläggning',
      fuelsButton: 'Bränslen...',
      fuels: 'Bränslen',
      removePlantButton: 'Ta bort anläggning',
      removeFuelButton: 'Ta bort rad',
      addFuelButton: 'Lägg till nytt bränsle',
      emissionFactor: 'Utsläppskoefficient',
      useDefault: 'Mata in egna siffror',
      titleAddPlant: 'Lägg till ny anläggning',
      titleModifyPlant: 'Ändra anläggning',
      searchBar: {
        fuel: 'Bränsle',
        loadingText: 'Hämtar...',
        placeholder: 'Sök bränslets namn',
        noResults: 'Inga bränslen hittades med sökfrågan',
      },
      table: {
        name: 'Namn',
        CHP: 'CHP-anläggning',
        yes: 'Ja',
        no: 'Nej',
        districtHeatingGWh: 'Värmeproduktion (GWh)',
        electricityGenerationGWh: 'Elproduktion (GWh)',
        fuels: 'Bränslen (Utsläppskoefficient)',
        EditTitle: 'Ändra',
        EditInfo: 'Ändra information om anläggningen',
        RemoveTitle: 'Ta bort',
        RemoveInfo: 'Ta bort anläggning',
        TableEmpty: 'Inga anläggningar',
      },
    },
    error: {
      percentageNot100: 'Summan av procentandelarna ska vara 100 %',
      duplicateName: 'Namnet finns redan',
    },
    dateFormatVisible: 'DD.MM.yyyy',
  },
  common: {
    alerts: {
      unsavedChangesTitle: 'Osparade ändringar',
      unsavedChangesContentTextLeaveStill: 'Vill du ändå lämna sidan?',
      unsavedChangesContentTextCancelChanges: 'Vill du ta bort osparade ändringar?',
      saveSuccesfull: 'Uppgifterna har sparats!',
      fillMissingFields: 'Fyll i de obligatoriska fälten',
    },
    buttons: {
      save: 'Spara',
      back: 'Tillbaka',
      leave: 'Lämna sidan',
      ok: 'Ok',
      cancel: 'Ångra',
      yes: 'Ja',
      no: 'Nej',
      import: 'Importera',
    },
    dateFormatVisible: 'DD.MM.yyyy',
    dateFormatVisibleHours: 'HH:mm',
    showMore: 'Visa mer...',
    showLess: 'Visa mindre',
  },
  admin: {
    users: {
      users: 'Användarnamn',
      addUser: 'Lägg till användarnamn',
      editUser: 'Ändra användarnamn',
      formTitle: 'Skapa nytt användarnamn för bolaget',
      userContactInformation: 'Användarens kontaktinformation',
      companyInformation: 'Uppgifter om bolaget',
      firstName: 'Förnamn',
      lastName: 'Efternamn',
      name: 'Namn',
      email: 'E-postadress',
      phone: 'Telefonnummer',
      company: 'Bolag',
      userActive: 'Användarnamnet aktivt',
      active: 'Aktivt',
      noUsers: 'Inga användarnamn',
      removeConfirm: 'Är du säker på att du vill ta bort användarnamnet {{email}}?',
    },
    companies: {
      companies: 'Bolag',
      name: 'Namn',
      addCompany: 'Lägg till bolag',
      companyId: 'FO-nummer',
      removeConfirm: 'Är du säker på att du vill ta bort bolaget {{company}}?',
      editConfirm: 'Är du säker på att du vill ändra bolagets namn {{nameOld}} till {{name}}?',
      nameAlreadyExists: 'Namnet finns redan.',
      companyIdAlreadyExists: 'Bolagets id finns redan',
    },
    navigationBar: {
      title: 'Utsläppsräknaren',
      logout: 'Logga ut',
      frontPage: 'Ingångssida',
      manageUsers: 'Hantering av användare',
      manageCompanies: 'Hantering av bolag',
      manageNews: 'Hantering av nyheter',
      logs: 'Logguppgifter',
    },
    searchBar: {
      label: 'Sök fjärrvärmebolag',
      loadingText: 'Hämtar... ',
      noResults: 'Inga resultat',
      placeholder: 'Du kan söka fjärrvärmebolag på namn',
      clear: 'Töm',
    },
    news: {
      editArticle: 'Ändra artikel',
      removeArticleButton: 'Ta bort artikel',
      publishNewArticle: 'Publicera ny artikel',
      expiredArticles: 'Föråldrade artiklar',
      title: 'Rubrik',
      ingress: 'Ingress',
      category: 'Kategori',
      author: 'Författare',
      subCategory: 'Underkategori',
      expireDate: 'Sista visningsdatum',
      articlePicture: 'Artikelbild',
      articleHasArticlePicture: 'Artikeln har en bild',
      pictureLink: 'Länk till bilden',
      pictureCaption: 'Bildtext',
      pictureAlt: 'Alt-text',
      articleBodyText: 'Nyhetens brödtext',
      showWritingInstructions: 'Visa skrivanvisningar',
      failedToFetchArticle: 'Fel: Artikeln kunde inte hämtas från servern',
      inFinnish: 'På finska',
      inSwedish: 'På svenska',
      inEnglish: 'På engelska',
      preview: 'Förhandsgranskning',
      addArticleButton: 'Publicera artikeln',
      editArticleButton: 'Spara ändringarna',
      dialogRemove: {
        content: 'Är du säker på att du vill ta bort artikeln från sidan slutgiltigt?',
        yes: 'Ja',
        no: 'Nej',
      },
      paragraphInstructions: {
        addNewParagraph: 'Börja ett nytt stycke genom att göra två radbyten',
        addHeader: 'Lägg till rubrik genom att inleda raden med "# " (utan citationstecken)',
        addImage: 'Lägg till bild genom att skriva ![alt-text](länk "Bildtext")',
        italics: 'Kursivera text i styckena genom att skriva *text* eller _text_',
        bold: 'Skriv text med fet stil i styckena genom att skriva **text** eller __text__',
        leading: 'Skriv ingresstext (fet stil och stor text) genom att skriva ***text*** eller ___text___',
        addLink: 'Skriv text som länk genom att skriva [text](url)',
        asteriskOrUnderscore: 'Skriv * genom att skriva \\* och _ genom att skriva \\_',

      },
    },
  },
  errors: {
    fillRequiredFields: 'Fyll i de obligatoriska fälten',
    invalidPhoneNumber: 'Felaktigt telefonnummer',
    invalidEmail: 'Felaktig e-postadress',
    networkImportFailed: 'Importering misslyckas. Om data ser ut bristfällig, ta kontatk med underhåll.',
    failedToSaveToDB: 'Det gick inte att spara ändringarna: {{error}}',
    networkError: 'Network error. {{code}}',
    networkErrorNoCode: 'Network error.',
  },
  warnings: {
    noNetworksAddedForYear: 'No networks exist for this year, <2>please add networks from the general tab first</2>.',
    noPlantsAddedForYear: 'No powerplants added for this year, <2>please add plants from the general tab first</2>.',
    noNetworksToPreview: 'No networks to preview, <2>please add networks from the general tab first</2>.',
  },
  errorBoundary: {
    title: 'Jokin meni vikaan...',
    subtitle: 'Päästölaskurissa tapahtui virhe. Yritä ladata sivu uudelleen. Jos uudelleen lataus ei toimi palaa alkuun.',
    button: 'Lataa sivu uudestaan',
  },
  news: {
    photo: 'Bild',
    edited: 'Uppdaterad',
    failedToFetchArticleList: 'Fel: Kunde inte hämta nyheter från servern.',
    failedToFetchArticle: 'Fel: Kunde inte hämta nyhet från servern.',
    noArticlesText: 'Denna sida är tom.',
  },
  months: {
    january: 'Januari',
    february: 'Februari',
    march: 'Mars',
    april: 'April',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    august: 'Augusti',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
    wholeYear: 'Helt år',
  },
};

export default svStrings;
