import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/user';
import type { RootState } from 'store';

const initialStateMock: User = {
  firstName: 'Erkki',
  lastName: 'Esimerkki',
  companyName: 'Kaukolämpö Oy',
  companyId: '43353253543',
  email: 'erkki.esimerkki@kaukolampo.fi',
  phone: '+358 123456789',
  enabled: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateMock,
  reducers: {
    changeUser(state, action: PayloadAction<{ newUser: User }>) {
      const { newUser } = action.payload;
      const stateVar = state;
      stateVar.companyName = newUser.companyName;
      stateVar.email = newUser.email;
      stateVar.firstName = newUser.email;
      stateVar.lastName = newUser.email;
      stateVar.phone = newUser.phone;
    },
  },
});

export const selectUser = (
) => (state:RootState): User => state.user;

export const {
  changeUser,
} = userSlice.actions;
export default userSlice.reducer;
