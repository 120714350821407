import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const ErrorView: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['main']);

  return (
    <div className={classes.root}>
      <Typography
        variant="h1"
        paragraph
      >
        {t('errorBoundary.title')}
      </Typography>
      <Typography
        variant="h3"
        paragraph
      >
        {t('errorBoundary.subtitle')}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => window.location.reload()}
      >
        {t('errorBoundary.button')}
      </Button>
    </div>
  );
};

export default ErrorView;
