import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Company, Network, NetworkDistributionArea, PowerPlant, Fuel, Trade,
} from 'types/companyData';
import type { AppThunk, RootState } from 'store';
import { findNetwork } from 'utils/find';
import { TradeDirType } from 'types/trading';
import { gql } from 'urql';
import { useClient } from 'graphql/client';

const initialState: Company & { isNetworksLoading: boolean } = {
  id: '',
  name: '',
  networks: [],
  isNetworksLoading: false,
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    updateCompany(state, action: PayloadAction<{ newCompanyData: Company }>) {
      const { newCompanyData: company } = action.payload;
      const stateCompany = state;
      stateCompany.id = company.id;
      stateCompany.name = company.name;
      stateCompany.networks = company.networks;
      stateCompany.emission = company.emission;
    },
    updateCompanyName(state, action: PayloadAction<{ name: string }>) {
      const { name } = action.payload;
      const statevar = state;
      statevar.name = name;
    },
    networksFetched(state, action: PayloadAction<Network[]>) {
      const newState = state;
      newState.networks = action.payload;
    },
    setNetworksLoading(state, action: PayloadAction<boolean>) {
      const newState = state;
      newState.isNetworksLoading = action.payload;
    },
    networkAdded(state, action: PayloadAction<Network>) {
      const newNetwork = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === newNetwork.id);

      if (!existingNetwork) {
        state.networks.push(newNetwork);
      } else {
        throw new Error('Network already exists');
      }
    },
    networkUpdated(state, action: PayloadAction<{
      networkId: string,
      updatedNetwork: Network
    }>) {
      const {
        networkId,
        updatedNetwork,
      } = action.payload;
      const { networks } = state;
      const existingNetworkIndex = networks.findIndex((network) => network.id === networkId);
      if (existingNetworkIndex > -1) {
        networks[existingNetworkIndex] = updatedNetwork;
      } else {
        throw new Error('Network not found');
      }
    },
    networkRemoved(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const existingNetworkId = state.networks.findIndex((network) => network.id === id);
      if (existingNetworkId > -1) {
        state.networks.splice(existingNetworkId, 1);
      } else {
        throw new Error('Network not found');
      }
    },
    areaAdded(state, action: PayloadAction<
    {
      networkId: string,
      newArea: NetworkDistributionArea
    }>) {
      const {
        networkId,
        newArea,
      } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingArea = existingNetwork.areas.find(
          (area) => area.id === newArea.id,
        );
        if (!existingArea) {
          existingNetwork.areas.push(newArea);
        } else {
          throw new Error('Area not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    areaUpdated(state, action: PayloadAction<
    {
      networkId: string,
      updatedArea: NetworkDistributionArea
    }>) {
      const {
        networkId,
        updatedArea,
      } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingAreaIndex = existingNetwork?.areas.findIndex(
          (area) => area.id === updatedArea.id,
        );
        if (existingAreaIndex > -1) {
          existingNetwork.areas[existingAreaIndex] = updatedArea;
        } else {
          throw new Error('Area not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    areaRemoved(state, action: PayloadAction<{ areaId: string, networkId: string }>) {
      const {
        areaId,
        networkId,
      } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingAreaIndex = existingNetwork.areas.findIndex(
          (locality) => locality.id === areaId,
        );
        if (existingAreaIndex > -1) {
          existingNetwork.areas.splice(existingAreaIndex, 1);
        } else {
          throw new Error('Area not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    plantAdded(state, action: PayloadAction<
    {
      networkId: string,
      newPlant: PowerPlant
    }
    >) {
      const {
        networkId,
        newPlant,
      } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlant = existingNetwork.areas.find(
          (plant) => plant.id === newPlant.id,
        );
        if (!existingPlant) {
          existingNetwork.plants.push(newPlant);
        } else {
          throw new Error('Plant already exists');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    plantRemoved(state, action: PayloadAction<{ plantId: string, networkId: string }>) {
      const {
        plantId,
        networkId,
      } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlantIndex = existingNetwork.plants.findIndex(
          (plant) => plant.id === plantId,
        );
        if (existingPlantIndex > -1) {
          existingNetwork.plants.splice(existingPlantIndex, 1);
        } else {
          throw new Error('Plant not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    plantUpdated(state, action: PayloadAction<
    {
      networkId: string,
      plantId: string,
      updatedPlant: PowerPlant | undefined,
    }
    >) {
      const {
        networkId,
        plantId,
        updatedPlant,
      } = action.payload;
      if (!updatedPlant) throw new Error('Plant is undefined');
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlantIndex = existingNetwork.plants.findIndex(
          (plant) => plant.id === plantId,
        );
        if (existingPlantIndex > -1) {
          existingNetwork.plants[existingPlantIndex] = updatedPlant;
        } else {
          throw new Error('Plant not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    fuelAdded(state, action: PayloadAction<
    {
      networkId: string,
      parentId: string,
      newFuel: Fuel
    }
    >) {
      const { networkId, parentId, newFuel } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlant = existingNetwork.plants.find((plant) => plant.id === parentId);
        if (existingPlant) {
          const existingFuel = existingPlant.fuels.find((fuel) => fuel.id === newFuel.id);
          if (!existingFuel) {
            existingPlant.fuels.push(newFuel);
          } else {
            throw new Error('Fuel already exists');
          }
        } else {
          const existingTrade = existingNetwork.trades.find((trade) => trade.id === parentId);
          if (existingTrade) {
            const existingFuel = existingTrade.fuels.find((fuel) => fuel.id === newFuel.id);
            if (!existingFuel) {
              existingTrade.fuels.push(newFuel);
            } else {
              throw new Error('Fuel already exists');
            }
          } else {
            throw new Error('Parent id not found');
          }
        }
      } else {
        throw new Error('Network not found');
      }
    },
    fuelsUpdated(state, action: PayloadAction<
    {
      networkId: string,
      parentId: string,
      fuels: Fuel[]
    }
    >) {
      const { networkId, parentId, fuels } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlant = existingNetwork.plants.find((plant) => plant.id === parentId);
        if (existingPlant) {
          existingPlant.fuels = [...fuels];
        } else {
          const existingTrade = existingNetwork.trades.find((trade) => trade.id === parentId);
          if (existingTrade) {
            existingTrade.fuels = [...fuels];
          } else {
            throw new Error('Parent id not found');
          }
        }
      } else {
        throw new Error('Network not found');
      }
    },
    fuelUpdated(state, action: PayloadAction<
    {
      networkId: string,
      parentId: string,
      updatedFuel: Fuel
    }
    >) {
      const { networkId, parentId, updatedFuel } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlant = existingNetwork.plants.find((plant) => plant.id === parentId);
        if (existingPlant) {
          const existingFuelIndex = existingPlant.fuels
            .findIndex((fuel) => fuel.id === updatedFuel.id);
          if (existingFuelIndex > -1) {
            existingPlant.fuels[existingFuelIndex] = updatedFuel;
          } else {
            throw new Error('Fuel not found');
          }
        } else {
          const existingTrade = existingNetwork.trades.find((trade) => trade.id === parentId);
          if (existingTrade) {
            const existingFuelIndex = existingTrade.fuels
              .findIndex((fuel) => fuel.id === updatedFuel.id);
            if (existingFuelIndex > -1) {
              existingTrade.fuels[existingFuelIndex] = updatedFuel;
            } else {
              throw new Error('Fuel not found');
            }
          } else {
            throw new Error('Parent id found');
          }
        }
      } else {
        throw new Error('Network not found');
      }
    },
    fuelRemoved(state, action: PayloadAction<
    {
      networkId: string,
      parentId: string,
      fuelId: string
    }
    >) {
      const { networkId, parentId, fuelId } = action.payload;
      const existingNetwork = state.networks.find((network) => network.id === networkId);
      if (existingNetwork) {
        const existingPlant = existingNetwork.plants.find((plant) => plant.id === parentId);
        if (existingPlant) {
          const existingFuelIndex = existingPlant.fuels.findIndex((fuel) => fuel.id === fuelId);
          if (existingFuelIndex > -1) {
            existingPlant.fuels.splice(existingFuelIndex, 1);
          } else {
            throw new Error('Fuel not found');
          }
        } else {
          const existingTrade = existingNetwork.trades.find((trade) => trade.id === parentId);
          if (existingTrade) {
            const existingFuelIndex = existingTrade.fuels.findIndex((fuel) => fuel.id === fuelId);
            if (existingFuelIndex > -1) {
              existingTrade.fuels.splice(existingFuelIndex, 1);
            } else {
              throw new Error('Fuel not found');
            }
          } else {
            throw new Error('Parent id not found');
          }
        }
      } else {
        throw new Error('Network not found');
      }
    },
    tradeAdded(state, action: PayloadAction<
    {
      networkId: string,
      newTrade: Trade
    }
    >) {
      const { networkId, newTrade } = action.payload;
      const existingNetwork = findNetwork(state, networkId);
      if (existingNetwork) {
        const existingTrade = existingNetwork.trades.find((trade) => trade.id === newTrade.id);
        if (!existingTrade) {
          existingNetwork.trades.push(newTrade);
        } else {
          throw new Error('Trade already exists');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    tradeRemoved(state, action: PayloadAction<
    {
      networkId: string,
      tradeId: string
    }
    >) {
      const { networkId, tradeId } = action.payload;
      const existingNetwork = findNetwork(state, networkId);
      if (existingNetwork) {
        const existingTradeIndex = existingNetwork.trades
          .findIndex((trade) => trade.id === tradeId);
        if (existingTradeIndex > -1) {
          existingNetwork.trades.splice(existingTradeIndex, 1);
        } else {
          throw new Error('Trade not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
    tradeUpdated(state, action: PayloadAction<
    {
      networkId: string,
      updatedTrade: Trade
    }
    >) {
      const { networkId, updatedTrade } = action.payload;
      const existingNetwork = findNetwork(state, networkId);
      if (existingNetwork) {
        const existingTradeIndex = existingNetwork.trades
          .findIndex((trade) => trade.id === updatedTrade.id);
        if (existingTradeIndex > -1) {
          existingNetwork.trades.splice(existingTradeIndex, 1, updatedTrade);
        } else {
          throw new Error('Trade not found');
        }
      } else {
        throw new Error('Network not found');
      }
    },
  },
});

const GET_COMPANY_QUERY = gql`
  query GetUserCompany($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`;

export const getCompany = (companyId: string): AppThunk => async (dispatch) => {
  const result = await useClient()
    .query<{ company: Company }>(
    GET_COMPANY_QUERY, { id: companyId }, { requestPolicy: 'network-only' },
  )
    .toPromise();

  const { error, data } = result;
  if (error) console.error(result.error);
  if (data && data.company) {
    dispatch(companySlice.actions.updateCompany({ newCompanyData: data.company }));
  }
};

const GET_NETWORKS = gql`
query GetNetworksByYear($companyId: ID!, $year: Int!) {
  company(id: $companyId) {
    networksByYear(year: $year) {
      id
      name
      description
      lossPercentage
      hasEmissionTrading
      year
      isFromHistory
      areas {
        id
        networkId
        location
        distributionPercentage
        year
        isFromHistory
      }
      plants {
        id
        networkId
        name
        districtHeating
        districtHeatingSeparate
        electricityGeneration
        wasteAndSurplusHeat
        processHeat
        isCHP
        year
        isFromHistory
      }
    }
  }
}
`;

const GET_NETWORKS_TABLE_VIEW = gql`
query GetNetworksByYear($companyId: ID!, $year: Int!) {
  company(id: $companyId) {
    networksByYear(year: $year) {
      id
      name
      year
      plants {
        id
        networkId
        name
        districtHeating
        districtHeatingSeparate
        electricityGeneration
        wasteAndSurplusHeat
        processHeat
        isCHP
        year
        isFromHistory
        fuels {
          id
          parentId
          networkId
          name
          consumption
          emissionFactor
          fuelProductionType
          parentId
          networkId
          code
          fuelType
          year
          isFromHistory
        }
      }
    }
  }
}
`;

export const getNetworksByYear = (companyId: string, year: number)
: AppThunk => async (dispatch) => {
  dispatch(companySlice.actions.setNetworksLoading(true));
  const { data, error } = await useClient()
    .query<{ company: { networksByYear: Network[] } }>(GET_NETWORKS, { companyId, year })
    .toPromise();

  if (error) console.error(error);

  if (data?.company?.networksByYear) {
    dispatch(companySlice.actions.networksFetched(data.company.networksByYear));
  }

  dispatch(companySlice.actions.setNetworksLoading(false));
};

export const getNetworksByYearTableView = (companyId: string, year: number)
: AppThunk => async (dispatch) => {
  dispatch(companySlice.actions.setNetworksLoading(true));
  const { data, error } = await useClient()
    .query<{ company: { networksByYear: Network[] } }>(GET_NETWORKS_TABLE_VIEW, { companyId, year })
    .toPromise();

  if (error) console.error(error);

  if (data?.company?.networksByYear) {
    dispatch(companySlice.actions.networksFetched(data.company.networksByYear));
  }

  dispatch(companySlice.actions.setNetworksLoading(false));
};

// Selectors

export const selectPlant = (
  networkId: string,
  plantId: string,
) => (state: RootState): PowerPlant | undefined => {
  const network = state.company.networks.find((elem) => elem.id === networkId);
  if (network) {
    const plant = network.plants.find((elem) => elem.id === plantId);
    return plant || undefined;
  }
  return undefined;
};

export const selectPlants = (
  networkId: string,
) => (state: RootState): PowerPlant[] | undefined => {
  const network = state.company.networks.find((elem) => elem.id === networkId);
  if (network) {
    return network.plants;
  }
  return undefined;
};

export const selectNetwork = (
  networkId: string,
) => (state: RootState): Network | undefined => {
  const network = state.company.networks.find((elem) => elem.id === networkId);
  return network || undefined;
};

export const selectNetworks = () => (state: RootState): Network[] => state.company.networks;

export const selectArea = (
  networkId: string,
  areaId: string,
) => (state: RootState): NetworkDistributionArea | undefined => {
  const network = state.company.networks.find((elem) => elem.id === networkId);
  if (network) {
    const locality = network.areas.find((elem) => elem.id === areaId);
    return locality || undefined;
  }
  return undefined;
};

export const selectFuel = (
  gridId: string,
  plantId: string,
  fuelId: string,
) => (state:RootState): Fuel | undefined => {
  const network = state.company.networks.find((elem) => elem.id === gridId);
  if (network) {
    const plant = network.plants.find((elem) => elem.id === plantId);
    if (plant) {
      const fuel = plant.fuels.find((elem) => elem.id === fuelId);
      return fuel || undefined;
    }
  }
  return undefined;
};

export const selectTrades = (
  networkId: string,
) => (state:RootState): Trade[] | undefined => {
  const network = state.company.networks.find((elem) => elem.id === networkId);
  if (network) {
    return network.trades;
  }
  return undefined;
};

export const selectTrade = (
  networkId: string,
  tradeId: string,
  type: TradeDirType,
) => (state: RootState): Trade | undefined => {
  const network = state.company.networks.find((elem) => elem.id === networkId);
  if (network) {
    switch (type) {
      case ('PURCHASE'):
        return network.trades.filter((t) => t.tradeType === 'PURCHASE').find((sale) => sale.id === tradeId);
      case ('SALE'):
        return network.trades.filter((t) => t.tradeType === 'SALE').find((sale) => sale.id === tradeId);
      default:
        return undefined;
    }
  }
  return undefined;
};

export const selectTradeFuel = (
  networkId: string,
  tradeId: string,
  type: TradeDirType,
  fuelId: string,
) => (state: RootState): Fuel | undefined => {
  const trade = selectTrade(networkId, tradeId, type)(state);
  if (trade) {
    return trade.fuels.find((fuel) => fuel.id === fuelId);
  }
  return undefined;
};

export const {
  networkAdded, areaAdded, areaRemoved, areaUpdated, fuelAdded, fuelRemoved,
  fuelUpdated, networkRemoved, networkUpdated, networksFetched, plantAdded,
  plantRemoved, plantUpdated, tradeAdded, tradeRemoved, tradeUpdated,
  updateCompanyName, updateCompany,
} = companySlice.actions;
export default companySlice.reducer;
