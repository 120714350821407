import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import {
  HouseType, ProcessType, StepperAction, StepperState, StepType, Target,
} from 'types/wizard';
import { canContinue } from 'Views/Public/Wizard/steps';

// wip, not in use

const initialState:StepperState = {
  activeStep: 0,
  steps: [''],
  inputs: {},
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    navigate(state: StepperState,
      action: PayloadAction<{ type: StepType, payload?: StepperAction }>) {
      const { type, payload } = action.payload;
      switch (type) {
        case 'setActiveStep':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.activeStep = payload as number;
          });
        case 'setSteps':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.steps = payload as string[];
          });
        case 'selectTarget':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.selectedTarget = payload as Target;
          });
        case 'setProcessType':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.processType = payload as ProcessType;
          });
        case 'setConsumption':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.inputs.consumption = payload as number;
          });
        case 'setHomeType':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.inputs.homeType = payload as HouseType;
          });
        case 'setHomeConstructionYear':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.inputs.constructionYear = payload as number;
          });
        case 'setSurfaceArea':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.inputs.surfaceArea = payload as number;
          });
        case 'previous':
          return produce(state, (draft) => {
            if (state.activeStep >= 1) {
              const draftVar = draft;
              draftVar.activeStep = state.activeStep - 1;
              if (draft.activeStep < 2) draftVar.processType = undefined;
              if (draft.activeStep === 0) draftVar.selectedTarget = undefined;
            }
          });
        case 'next':
          return produce(state, (draft) => {
            if (state.activeStep < state.steps.length && canContinue(state)) {
              const draftVar = draft;
              draftVar.activeStep = state.activeStep + 1;
            }
          });
        case 'reset':
          return produce(state, (draft) => {
            const draftVar = draft;
            draftVar.activeStep = 0;
            draftVar.selectedTarget = undefined;
            draftVar.processType = undefined;
            draftVar.inputs = {};
          });
        default:
          throw new Error('Invalid action');
      }
    },
  },
});

export const {
  navigate,
} = wizardSlice.actions;
export default wizardSlice.reducer;
