import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'store';
import { gql } from 'urql';
import { Company } from 'types/companyData';
import { useClient } from 'graphql/client';

export interface AddCompanyInput {
  id: string;
  name?: string;
}

const initialState: { companies: AddCompanyInput[] } = { companies: [] };

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    editCompany(state, action: PayloadAction<{ updatedCompany: AddCompanyInput }>) {
      const { updatedCompany } = action.payload;
      const { companies } = state;
      const existingCompanyIndex = companies
        .findIndex((company) => company.id === updatedCompany.id);
      if (existingCompanyIndex > -1) {
        companies[existingCompanyIndex] = {
          ...companies[existingCompanyIndex],
          ...updatedCompany,
        };
      } else {
        throw new Error('Company not found');
      }
    },
    removeCompany(state, action: PayloadAction<{ companyId: string }>) {
      const { companyId } = action.payload;
      const { companies } = state;
      const existingCompanyIndex = companies
        .findIndex((company) => company.id === companyId);
      if (existingCompanyIndex > -1) {
        companies.splice(existingCompanyIndex, 1);
      } else {
        throw new Error('Company not found');
      }
    },
    addCompany(state, action: PayloadAction<{ company: AddCompanyInput }>) {
      const { company } = action.payload;
      const { companies } = state;
      const existingCompany = companies.find((c) => c.id === company.id);
      if (!existingCompany) {
        companies.push(company);
      } else {
        throw new Error('Company already exists');
      }
    },
    setCompanies(state, action: PayloadAction<{ newCompanies: AddCompanyInput[] }>) {
      const { newCompanies } = action.payload;
      const companies = state;
      companies.companies = newCompanies;
    },
  },
});

// TODO: Require authorization?
const GET_COMPANIES_QUERY = gql`
  query Companies($filter: String) {
    companies(filter: $filter) {
      id
      name
    }
  }
`;

export const getCompanies = ():AppThunk => async (dispatch) => {
  const result = await useClient()
    .query<{ companies: Company[] }>(
    GET_COMPANIES_QUERY, {}, { requestPolicy: 'network-only' },
  )
    .toPromise();
  const { error, data } = result;
  if (error) console.error(result.error);
  if (data && data.companies) {
    dispatch(companiesSlice.actions.setCompanies({ newCompanies: data.companies }));
  }
};

export const selectCompany = (
  companyId: string,
) => (state: RootState): AddCompanyInput | undefined => {
  const { companies } = state;
  const company = companies.companies.find((elem) => elem.id === companyId);
  if (company) {
    return company;
  }
  return undefined;
};

export const selectCompanies = (
) => (state: RootState): AddCompanyInput[] | undefined => state.companies.companies;

export const {
  editCompany, addCompany, setCompanies, removeCompany,
} = companiesSlice.actions;
export default companiesSlice.reducer;
