import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import LoadingScreen from './LoadingScreen';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isInitialised, user } = useAuth();

  if (!isInitialised) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (user.passwordChallenge) {
    return <Redirect to="/account/changePassword" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default AuthGuard;
