import i18next from 'i18next';

type Locale = 'fi-FI' | 'en-GB';

const getLocale = ():Locale => {
  switch (i18next.language) {
    case ('fi'):
      return 'fi-FI';
    case ('sv'):
      return 'fi-FI';
    case ('en'):
      return 'en-GB';
    default:
      return 'fi-FI';
  }
};

export default getLocale;
