import { Provider } from 'urql';
import React, { FC, useMemo } from 'react';

import useAuth from 'hooks/useAuth';
import ClientFactory from './client';

const UrqlProvider: FC = ({ children }) => {
  const { user } = useAuth();
  const client = useMemo(() => ClientFactory(), [user]);

  return (
    <Provider value={client}>
      {children}
    </Provider>
  );
};

export default UrqlProvider;
